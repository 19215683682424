import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

//Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Context & APIs
import { createUser, getAllRightsForTree, getRoleGrid, getRoleRights } from "../../../../../services/user-management/user";
import { getCountryCode } from "../../../../../services/country-code";
import { ToastContext } from "../../../../../context/toast";

//ui components
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";
import CustomCancelbtn from "../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";

// Prime React
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";
import { classNames } from "primereact/utils";
import { InputMask } from "primereact/inputmask";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from "primereact/accordion";
import { textExtraSpaceRemoval } from "../../../../../utils/HelperFuctions/cleanData";

// Redux
import { onFormDirty } from "../../../../../redux/main-view";
import { useDispatch, useSelector } from "react-redux";
import { loginState } from "../../../../../redux/login";

// Constants
import { USERMANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";

// Utils
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { mainViewState } from "../../../../../redux/main-view";

const USER_MANAGEMNT_MODULE_ICON_MAP = {
    Management: <i className="bi bi-people mr-3"></i>,
    Transactions: <i className="bi bi-arrow-repeat mr-3"></i>,
    "Credit Request": <i className="bi bi-credit-card-2-front mr-3"></i>,
    Underwriting: <i className="bi bi-file-earmark-text mr-3"></i>,
    Reports: <i className="bi bi-file-earmark-bar-graph mr-3"></i>,
    Support: <i className="bi bi-headset mr-3"></i>,
    "User Management": <i className="pi pi-users mr-3"></i>,
    Settings: <i className="bi bi-toggle-off mr-3"></i>,
    Chats: <i className="bi bi-chat-dots mr-3"></i>,
    Documents: <i className="bi bi-file-earmark-word mr-3"></i>,
    Dashboard: <i className="bi bi-columns-gap mr-3"></i>,
    "General Ledger": <i className="bi bi-journal-text mr-3"></i>,
};

const AddNewUser = () => {
    const history = useHistory();
    const location = useLocation();
    const toastContext = useContext(ToastContext);
    const [isSaving, setIsSaving] = useState(false);
    const [modules, setModules] = useState();
    const [activeModule, setActiveModule] = useState();
    const [subModule, setSubModule] = useState();
    const [checkedRights, setCheckedRights] = useState([]);
    const [roleDropDown, setRoleDropDown] = useState();
    const [uncheckedModules, setUncheckedModules] = useState([]);
    const [countryCodes, setCountryCodes] = useState();
    const [dialogVisible, setDialogVisible] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRoleChanged, setIsRoleChanged] = useState(false);
    const [checked, setChecked] = useState(false);

    // Redux
    const { user } = useSelector(loginState);
    const { screens } = useSelector(mainViewState);

    const BreakcrumbItems = [{ label: "User Profiles", url: "/userindex" }, { label: location?.state?.user ? "Edit User" : "Add New User" }];
    const homeIcon = { icon: "bi bi-people" };

    // Hooks
    const dispatch = useDispatch();

    // Form
    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required("First Name is required"),
        username: Yup.string().required("Username is required"),
        email: Yup.string().required("Email is required").email("Invalid Email"),
        gender: Yup.string().required("Gender is required"),
        phone: Yup.string().required("Phone No is required"),
        country: Yup.string().required("Country is required"),
        rolename: Yup.string().required("Role Title is required").nullable(),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            gender: "",
            phone: "",
            country: "+1",
            address: "",
            rolename: null,
            sensitiveInformationDisclosure: false,
        },
        onSubmit: async (data) => {
            try {
                setIsSaving(true);
                setIsLoading(true);
                const dto = {
                    first_name: data.firstname,
                    last_name: data.lastname,
                    email: data.email,
                    username: data.username,
                    country_phone_code: data.country,
                    phone_number: data.phone,
                    gender: data.gender,
                    address: data.address,
                    role_id: data.rolename,
                    sensitive_information_disclosure: data.sensitiveInformationDisclosure,
                    rights_list: checkedRights.map((r) => {
                        return {
                            rightsId: r,
                            isAllowed: true,
                        };
                    }),
                    role_type: roleDropDown.find((option) => option.roleId === data.rolename)?.roleName || "",
                };
                if (isEditing) {
                    dto["sso_id"] = location?.state?.user?.user_Id;
                }
                let resp = await createUser(dto);
                setIsSaving(false);
                setIsLoading(false);
                if (resp.data.status) {
                    if (isEditing) {
                        toastContext.updateToast("User");
                    } else {
                        toastContext.createdToast("User");
                    }
                    formik.resetForm();
                    dispatch(onFormDirty(false));
                    history.push("/userindex");
                } else {
                    toastContext.showMessage("User", resp.data.message, "error");
                }
                return;
            } catch (error) {
                console.log({ error });
                toastContext.showMessage("User", `User ${isEditing ? "updated" : "creation"} failed. Admin has been notified.`, "error");
                setIsLoading(false);
                setIsSaving(false);
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const genderOption = [
        { name: "Male", value: "Male" },
        { name: "Female", value: "Female" },
    ];

    const getRoles = async () => {
        let dto = {
            pageNumber: 1,
            size: 1000,
            search: "",
            status: true,
        };
        let resp = await getRoleGrid(dto);
        setRoleDropDown(resp.data.records);
    };

    const updateRightsList = (id, action) => {
        if (action === "add") {
            if (!checkedRights.includes(id)) {
                let data = checkedRights;
                data.push(id);
                setCheckedRights(data);
            }
        } else if (action === "remove") {
            const index = checkedRights.indexOf(id);
            if (index !== -1) {
                let data = checkedRights;
                data.splice(index, 1);
                setCheckedRights(data);
            }
        }
    };

    const getTreeData = async () => {
        let resp = await getAllRightsForTree();
        let module = resp.data.map((m) => {
            return {
                moduleName: m.moduleName,
                checked: "none",
                subModuleNameDTO: m.subModuleNameDTO.map((sub) => {
                    return {
                        subModuleName: sub.subModuleName,
                        expanded: false,
                        checked: "none",
                        subModuleRightsListDTO: sub.subModuleRightsListDTO.map((rights) => {
                            return {
                                rightsName: rights.rightsName,
                                displayName: rights.displayName,
                                rightsId: rights.rightsId,
                                checked: false,
                            };
                        }),
                    };
                }),
            };
        });
        setUncheckedModules(module);
        setModules(module);
        setActiveModules(0, module);
        if (location?.state?.user) {
            setRoleValues(location?.state?.user?.userRights, module);
        }
        return module;
    };

    const setActiveModules = (index, data = modules) => {
        setActiveModule(data[index].moduleName);
        setSubModule(data[index].subModuleNameDTO);
    };

    const checkRight = (right, subModule, index) => {
        try {
            if (right.checked) {
                updateRightsList(right.rightsId, "remove");
            } else {
                updateRightsList(right.rightsId, "add");
            }
            right.checked = !right.checked;
            let isAllChecked = true;
            let isAnyChecked = false;
            subModule.subModuleRightsListDTO.forEach((r) => {
                if (r.checked) {
                    isAnyChecked = true;
                } else {
                    isAllChecked = false;
                }
            });

            if (isAllChecked) {
                subModule.checked = "all";
            } else if (isAnyChecked) {
                subModule.checked = "some";
            } else {
                subModule.checked = "none";
            }

            let activeModuleIndex = modules.findIndex((m) => m.moduleName === activeModule);

            let moduleData = [...modules];
            moduleData[activeModuleIndex].subModuleNameDTO[index] = subModule;

            setModules(moduleData);
            setSubModule(moduleData[activeModuleIndex].subModuleNameDTO);
            checkModuleOnSubmoduleChange();
        } catch (error) {
            console.log(error);
        }
    };

    const checkSubmodule = (subModule, index) => {
        subModule.subModuleRightsListDTO.forEach((r) => {
            if (subModule.checked == "all") {
                updateRightsList(r.rightsId, "remove");
            } else {
                updateRightsList(r.rightsId, "add");
            }
            r.checked = subModule.checked == "all" ? false : true;
        });
        if (subModule.checked === "all") {
            subModule.checked = "none";
        } else if (subModule.checked === "some" || subModule.checked === "none") {
            subModule.checked = "all";
        }

        let activeModuleIndex = modules.findIndex((m) => m.moduleName === activeModule);

        let moduleData = [...modules];
        moduleData[activeModuleIndex].subModuleNameDTO[index] = subModule;
        setModules(moduleData);
        setSubModule(moduleData[activeModuleIndex].subModuleNameDTO);
        checkModuleOnSubmoduleChange();
    };

    const checkAllSubmodules = (name) => {
        let index = modules.findIndex((m) => m.moduleName === name);
        if (index != -1) {
            let subModule = modules[index].subModuleNameDTO;
            subModule.forEach((sub) => {
                sub.checked = modules[index].checked == "all" ? "none" : "all";
                sub.subModuleRightsListDTO.forEach((r) => {
                    if (modules[index].checked == "all") {
                        updateRightsList(r.rightsId, "remove");
                    } else {
                        updateRightsList(r.rightsId, "add");
                    }
                    r.checked = modules[index].checked == "all" ? false : true;
                });
            });
        }
        if (modules[index].checked === "all") {
            modules[index].checked = "none";
        } else if (modules[index].checked === "some" || modules[index].checked === "none") {
            modules[index].checked = "all";
        }
        setModules([...modules]);
        setSubModule({ ...subModule });
    };

    const checkModuleOnSubmoduleChange = () => {
        let index = modules.findIndex((m) => m.moduleName === activeModule);
        let isAllChecked = true;
        let isAnyChecked = false;
        if (index != -1) {
            subModule.forEach((sub) => {
                sub.subModuleRightsListDTO.forEach((r) => {
                    if (r.checked) {
                        isAnyChecked = true;
                    } else {
                        isAllChecked = false;
                    }
                });
            });
        }

        if (isAllChecked) {
            modules[index].checked = "all";
        } else if (isAnyChecked) {
            modules[index].checked = "some";
        } else {
            modules[index].checked = "none";
        }
        setModules([...modules]);
        setSubModule([...subModule]);
    };

    const setRoleValues = (rights, modules) => {
        let checkedRights = [];
        rights.forEach((r) => {
            checkedRights.push(r.rightsId);
        });
        setCheckedRights(checkedRights);

        modules.forEach((module) => {
            let isAllCheckedModule = true;
            let isAnyCheckedModule = false;
            module.subModuleNameDTO.forEach((sub) => {
                let isAllCheckedSubModule = true;
                let isAnyCheckedSubModule = false;
                sub.subModuleRightsListDTO.forEach((right) => {
                    let index = checkedRights.findIndex((c) => c === right.rightsId);
                    if (index != -1) {
                        right.checked = true;
                        isAnyCheckedSubModule = true;
                    } else {
                        isAllCheckedSubModule = false;
                    }
                });
                if (isAllCheckedSubModule) {
                    sub.checked = "all";
                } else if (isAnyCheckedSubModule) {
                    sub.checked = "some";
                } else {
                    sub.checked = "none";
                }
            });
            module.subModuleNameDTO.forEach((sub) => {
                if (sub.checked == "all") {
                    isAnyCheckedModule = true;
                } else if (sub.checked == "none") {
                    isAllCheckedModule = false;
                } else if (sub.checked == "some") {
                    isAnyCheckedModule = true;
                    isAllCheckedModule = false;
                }
            });
            if (isAllCheckedModule) {
                module.checked = "all";
            } else if (isAnyCheckedModule) {
                module.checked = "some";
            } else {
                module.checked = "none";
            }
        });
        setModules([...modules]);
    };

    const onRoleChnage = async () => {
        clearModulesAndSubmodules();
        const roleId = Number(formik.values.rolename);
        const dto = {
            role_id: roleId,
        };
        const resp = await getRoleRights(dto);
        const rights = resp.data.rights;
        setRoleValues(rights, uncheckedModules);
    };

    const clearModulesAndSubmodules = () => {
        setCheckedRights([]);
        setModules(uncheckedModules);
        setActiveModule(uncheckedModules[0]?.moduleName);
        setSubModule(uncheckedModules[0]?.subModuleNameDTO);
    };

    const cancel = () => {
        if (formik.dirty || checkedRights.length != 0) {
            setDialogVisible(true);
        } else {
            history.push("/userindex");
        }
    };

    const confirmCancel = () => {
        formik.resetForm();
        dispatch(onFormDirty(false));
        history.push("/userindex");
    };

    const countryCode = async () => {
        let resp = await getCountryCode();
        setCountryCodes(resp.data.countries);
    };

    const inputHandler = (e) => {
        const { name, value } = e.target;
        const cleanValue = textExtraSpaceRemoval(value);

        formik.setFieldValue(name, onInputDataCleaner(name, cleanValue));

        if (!screens?.main?.isFormDirty) {
            dispatch(onFormDirty(true));
        }
    };

    const onInputDataCleaner = (type, value) => {
        switch (type) {
            case "username":
                return value.replace(/\s/g, "");
            default:
                return value;
        }
    };

    useEffect(() => {
        if (!location?.state?.user) return;

        let user = location?.state?.user;
        setIsEditing(true);
        formik.setFieldValue("firstname", user?.first_Name ?? "");
        formik.setFieldValue("lastname", user?.last_Name ?? "");
        formik.setFieldValue("username", user?.user_Name ?? "");
        formik.setFieldValue("email", user?.email ?? "");
        formik.setFieldValue("gender", user?.gender ?? "");
        formik.setFieldValue("phone", user?.contact ?? "");
        formik.setFieldValue("country", user?.country_phone_code ?? "+1");
        formik.setFieldValue("address", user?.address ?? "");
        formik.setFieldValue("rolename", user?.role_Id ?? "");
        formik.setFieldValue("sensitiveInformationDisclosure", user?.sensitive_information_disclosure ?? false);
    }, [location?.state?.user]);

    useEffect(() => {
        if (!validateUserPermissions([USERMANAGMENT_RIGHTS.user_profile_selection], user?.rights)) {
            history.goBack();
        }

        return () => {
            dispatch(onFormDirty(false));
        };
    }, [user]);

    useEffect(() => {
        const getData = async () => {
            getTreeData();
            getRoles();
            countryCode();
        };

        getData();
    }, []);

    useEffect(() => {
        // console.log(location?.state?.user, location?.state?.user.role_Id, Number(formik.values.rolename));
        if (formik.values.rolename != null) {
            if (!location?.state?.user) {
                setIsRoleChanged(true);
                onRoleChnage();
                return;
            }
            if (isRoleChanged) {
                onRoleChnage();
                return;
            }
            if (location?.state?.user && location?.state?.user.role_Id != Number(formik.values.rolename)) {
                setIsRoleChanged(true);
                onRoleChnage();
                return;
            }
        }
    }, [formik.values.rolename]);

    return (
        <>
            <h4 className="pt-2">
                <BreadCrumb model={BreakcrumbItems} home={homeIcon} />
                {/* <b> {location?.state?.user ? "Edit User" : "Add New User"}</b> */}
            </h4>
            <div className="card add_new_user">
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <CustomInputField
                            maxLength={20}
                            className="col-12 md:col-3"
                            iden="firstname"
                            label="First Name "
                            mandatory="*"
                            formik={formik}
                            placeHolder="Enter First Name"
                            onChange={(e) => inputHandler(e)}
                        />
                        <CustomInputField
                            maxLength={20}
                            className="col-12 md:col-3"
                            iden="lastname"
                            label="Last Name"
                            formik={formik}
                            placeHolder="Enter Last Name"
                            onChange={(e) => inputHandler(e)}
                        />
                        <CustomInputField
                            maxLength={35}
                            className="col-12 md:col-3"
                            iden="email"
                            label="Email"
                            mandatory="*"
                            type="email"
                            formik={formik}
                            placeHolder="Enter Email"
                            disabled={isEditing}
                            onChange={(e) => inputHandler(e)}
                        />
                        <CustomInputField
                            maxLength={30}
                            className="col-12 md:col-3"
                            iden="username"
                            label="Username"
                            mandatory="*"
                            formik={formik}
                            placeHolder="Enter Username"
                            disabled={isEditing}
                            onChange={(e) => inputHandler(e)}
                        />

                        <div className="col-12 md:col-3">
                            <label htmlFor="phone">
                                <b style={{ fontSize: "14px" }}>Phone No</b> <span className="clr_red">*</span>
                            </label>
                            <div className={classNames({ "p-invalid": isFormFieldValid("phone") }, "border_div")}>
                                <Dropdown
                                    id="country"
                                    name="country"
                                    value={formik.values.country}
                                    className="custom_width_country"
                                    options={countryCodes}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        if (!screens?.main?.isFormDirty) {
                                            dispatch(onFormDirty(true));
                                        }
                                    }}
                                    optionLabel="phone_code"
                                    optionValue="phone_code"
                                    placeholder="+1"
                                    filter
                                    filterBy="phone_code"
                                />
                                <InputMask
                                    name="phone"
                                    id="phone"
                                    mask="999-999-9999"
                                    value={formik.values.phone}
                                    placeholder="999-999-9999"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        if (!screens?.main?.isFormDirty) {
                                            dispatch(onFormDirty(true));
                                        }
                                    }}
                                    className="custom_width_phone"
                                    onBlur={formik.handleBlur}
                                ></InputMask>
                            </div>
                            {getFormErrorMessage("phone")}
                        </div>
                        <CustomInputField
                            className="col-12 md:col-3"
                            type="dropdown"
                            iden="gender"
                            label="Gender"
                            formik={formik}
                            options={genderOption}
                            onChange={(e) => {
                                formik.handleChange(e);
                                if (!screens?.main?.isFormDirty) {
                                    dispatch(onFormDirty(true));
                                }
                            }}
                            optionLabel="name"
                            optionValue="value"
                            placeHolder="Select"
                            mandatory="*"
                        />
                        <CustomInputField maxLength={60} className="col-12 md:col-6" iden="address" label="Address" formik={formik} placeHolder="Enter Address" onChange={(e) => inputHandler(e)} />

                        <Checkbox
                            checked={formik.values.sensitiveInformationDisclosure}
                            inputId="sid"
                            // checked={checked}
                            // disabled={termsheetExist}
                            onChange={(e) => {
                                formik.handleChange(e);
                                if (!screens?.main?.isFormDirty) {
                                    dispatch(onFormDirty(true));
                                }
                                setChecked(e.checked);
                                formik.setFieldValue("sensitiveInformationDisclosure", e.checked);
                            }}
                            className="mt-2 ml-1"
                        />
                        <label htmlFor="sid" className="mt-2 ml-2 sid-mt checkbox-label"><strong>Access to Sensitive Information?</strong></label>

                    </div>

                    <div className="grid pt-5">
                        <div className="md:col-4 col-12">
                            <div>
                                <div className="role_card">
                                    <CustomInputField
                                        className="col-12"
                                        type="dropdown"
                                        iden="rolename"
                                        label="Role Title"
                                        formik={formik}
                                        options={roleDropDown}
                                        optionLabel="roleName"
                                        optionValue="roleId"
                                        placeHolder="Select"
                                        mandatory="*"
                                    />
                                </div>

                                <div className="custom_card marg__top">
                                    <div className="col-12">
                                        <h5>Modules</h5> <Divider />
                                    </div>
                                    <div className="col-12">
                                        <div className="custom_modal_scrollbar">
                                            {modules?.map((m, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        onClick={() => {
                                                            setActiveModules(index);
                                                        }}
                                                        className={`custom_div cursor-pointer ${activeModule === m.moduleName ? "active_module" : ""}`}
                                                    >
                                                        <div className={`flex flex-row justify-content-between`}>
                                                            <span>
                                                                <p>
                                                                    {/* <i className="pi pi-users mr-3"></i> */}
                                                                    {USER_MANAGEMNT_MODULE_ICON_MAP[m.moduleName]}
                                                                    {m.moduleName}
                                                                </p>
                                                            </span>
                                                            <span>
                                                                <Checkbox
                                                                    className={`${m.checked === "some" ? "indeterminate_checkbox" : ""}`}
                                                                    checked={m.checked === "all"}
                                                                    onChange={() => {
                                                                        checkAllSubmodules(m.moduleName);
                                                                        if (!screens?.main?.isFormDirty) {
                                                                            dispatch(onFormDirty(true));
                                                                        }
                                                                    }}
                                                                    inputId="accept"
                                                                    name="accept"
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:col-8 col-12">
                            <div className="custom_card user_right_card">
                                <div className="custom_card_accordian_scrollbar">
                                    <div className="col-12">
                                        <h5>Rights</h5> <Divider />
                                    </div>
                                    {subModule?.map((sub, index) => (
                                        <Accordion className="add_new_user_accordian" key={index}>
                                            <AccordionTab
                                                header={
                                                    <div className="flex align-items-center">
                                                        <Checkbox
                                                            checked={sub.checked === "all"}
                                                            indeterminate={sub.checked === "some"}
                                                            onChange={() => {
                                                                checkSubmodule(sub, index);
                                                                if (!screens?.main?.isFormDirty) {
                                                                    dispatch(onFormDirty(true));
                                                                }
                                                            }}
                                                            className={`${sub.checked === "some" ? "indeterminate_checkbox" : ""} mr-2`}
                                                            inputId="submodule"
                                                            name="submodule"
                                                            value="submodule"
                                                        />
                                                        <span htmlFor="submodule" className="pl-2">
                                                            {sub?.subModuleName}
                                                        </span>
                                                    </div>
                                                }
                                            >
                                                <div className="pt-3 grid">
                                                    {sub?.subModuleRightsListDTO?.map((right, index2) => (
                                                        <div key={index2} className="md:col-3 col-12">
                                                            <div className="flex align-items-center">
                                                                <Checkbox
                                                                    checked={right.checked}
                                                                    onChange={() => {
                                                                        checkRight(right, sub, index);

                                                                        if (!screens?.main?.isFormDirty) {
                                                                            dispatch(onFormDirty(true));
                                                                        }
                                                                    }}
                                                                    inputId={index2}
                                                                    name={index2}
                                                                    value={index2}
                                                                />
                                                                <span htmlFor={index2} className="pl-2 pb-2">
                                                                    {right.displayName}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </AccordionTab>
                                        </Accordion>
                                    ))}
                                </div>
                            </div>
                            <div className="col-12 text-right pt-5 pr-0">
                                <CustomCancelbtn title="Cancel" type="button" onClick={cancel} />
                                <CustomAddNewButton
                                    title={location?.state?.user ? "Update" : "Create"}
                                    type="submit"
                                    isDisabled={isSaving || (!screens?.main?.isFormDirty ?? false)}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {dialogVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setDialogVisible(false)}
                    handler={confirmCancel}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default AddNewUser;
