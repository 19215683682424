import axios from "axios";
import { refreshTokenAPI } from "../../services/user-management/user";
export async function refreshToken() {
    try {
        const accessToken = localStorage.getItem("accessToken");
        let decodedToken = await decodeJwtToken(accessToken);
        const currentTime = Math.floor(new Date().getTime() / 1000);
        const timeDifference = (decodedToken.exp - currentTime) / 60;

        if (timeDifference <= 2) {
            let resp = await refreshTokenAPI();
            localStorage.setItem("accessToken", resp.data.access_token);
            localStorage.setItem("refreshToken", resp.data.refresh_token);

            // let dateObject = new Date(resp.data.user.tokenExpTime);
            // document.cookie = `access_token=${resp.data.access_token}; expires=${dateObject.toUTCString()}; path=/`;
        }
    } catch (error) {}
}

export async function decodeJwtToken(accessToken) {
    var base64Url = accessToken.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    return JSON.parse(jsonPayload);
}