import React, { useEffect, useState } from "react";
import Header from "./Header";
import SearchInputComponent from "../../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../../ui/ColumnFilter/ColumnFilterComponent";
import CreditRequestKebabMenuComponent from "./CreditRequestKebabMenuComponent";
import { Checkbox } from "primereact/checkbox";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../redux/login";
import { CLIENT_CREDIT_GRID_HEADERS_FOR_CLIENT, ROLE_NAME } from "../../../../../../utils/Constants/global";
import Legends from "../../../../../../ui/Legends/Legends";

const FilterBar = (props) => {
    const {
        setShowAll,
        showAll,
        setKeyword,
        keyword,
        gridColumns,
        setSelectedColumns,
        isClientRequest,
        selectedCreditRequests,
        kebabMenuItems,
        kebabMenuItemClick,
        kebabMenuItemLoader,
        handleOpenAddCreditRequestDialog,
    } = props;
    const [onSelectionColumn] = useState([]);

    const { user } = useSelector(loginState);

    const handleOpenMenuItems = (status) => {
        if (status == 1 && selectedCreditRequests.length !== 0) {
        } else if (status == 2 && selectedCreditRequests.length !== 0) {
        } else if (status == 3 && selectedCreditRequests.length !== 0) {
        }

        if (status == 1) {
            kebabMenuItemClick(status);
        } else if (selectedCreditRequests.length !== 0 && !kebabMenuItemLoader) {
            kebabMenuItemClick(status);
        }
    };

    useEffect(() => {
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            CLIENT_CREDIT_GRID_HEADERS_FOR_CLIENT.filter((col) => {
                onSelectionColumn.push(col.field);
            });
        } else {
            gridColumns.filter((col) => {
                onSelectionColumn.push(col.field);
            });
        }
    }, []);

    return (
        <>
            <div className="col-12 md:col-10">
                <h4 className="m-0">
                    <b>{isClientRequest ? "Client" : "Debtor"} Credit Requests</b>
                </h4>
            </div>

            <div className="md:col-6 col-12">
                <div className="flex mt-4">
                    <Checkbox inputId="showall" checked={showAll === 1 ? true : false} onChange={(e) => setShowAll(e.checked ? 1 : 0)} />
                    <label htmlFor="showall" className="mt-1 ml-2 checkbox-label">
                        Include terminated
                    </label>
                    {showAll === 1 && (
                        <>
                            <div className="mt-1 ml-3">
                                <Legends classes="square-legend-delete" title="Deleted" />
                            </div>
                            <div className="mt-1 ml-2">
                                <Legends classes="square-legend-terminate" title="Terminated" />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="md:col-6 col-12">
                <div className="flex flex-row">
                    <SearchInputComponent type="text" value={keyword} setKeyword={setKeyword} placeholder={`Search by client name, ${isClientRequest ? "status" : "debtor name"}`} />
                    <div className="ml-2">
                        <ColumnFilterComponent
                            items={user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT ? CLIENT_CREDIT_GRID_HEADERS_FOR_CLIENT : gridColumns}
                            handleColumnFilter={(e) => setSelectedColumns(e)}
                            selectedColumn={onSelectionColumn}
                        />
                    </div>
                    <div className={`ml-2 ${kebabMenuItemLoader ? "no-drop" : ""}`}>
                        {kebabMenuItems.length > 0 && (
                            <CreditRequestKebabMenuComponent items={kebabMenuItems} handleMenuOpen={handleOpenMenuItems} selected={selectedCreditRequests} isDisabled={kebabMenuItemLoader} />
                        )}
                    </div>
                    <div className="ml-2">
                        <Header isClientRequest={isClientRequest} handleOpenAddCreditRequestDialog={handleOpenAddCreditRequestDialog} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterBar;
