import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Checkbox } from "primereact/checkbox";
import SearchInputComponent from "../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../ui/ColumnFilter/ColumnFilterComponent";
import { DEBTOR_GRID_HEADERS, DEBTOR_KEBAB_MENU_ITEMS, DEBTOR_GRID_HEADERS_FOR_CLIENT, ROLE_NAME } from "../../../../../utils/Constants/global";
import DebtorKebabMenuComponent from "./DebtorKebabMenuComponent";
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../redux/login";
import Legends from "../../../../../ui/Legends/Legends";

let columns = DEBTOR_GRID_HEADERS;

const FilterBar = (props) => {
    const { setShowAll, showAll, setKeyword, keyword, setSelectedColumns, selectedDebtors, kebabMenuItems, kebabMenuItemClick, kebabMenuItemLoader } = props;

    // States
    const [onSelectionColumn] = useState([]);
    const [kebab_menu_items, setKebabMenuItems] = useState([]);

    // Redux
    const { user } = useSelector(loginState);

    // Handlers
    const handleOpenMenuItems = (status) => {
        if (status == 1 && selectedDebtors.length !== 0) {
        } else if (status == 2 && selectedDebtors.length !== 0) {
        } else if (status == 3 && selectedDebtors.length !== 0) {
        }

        if (status == 1) {
            kebabMenuItemClick(status);
        } else if (selectedDebtors.length !== 0 && !kebabMenuItemLoader) {
            kebabMenuItemClick(status);
        }
    };

    // Effects
    useEffect(() => {
        columns = user?.userRoles[0]["roleName"] !== ROLE_NAME.CLIENT
            ? columns
            : columns.filter(header => header.field !== "client_name");

        columns = user.sensitive_information_disclosure
        ? columns
        : columns.filter(header => header.field !== "fein");
    }, [])

    useEffect(() => {
        // if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
        //     DEBTOR_GRID_HEADERS_FOR_CLIENT.filter((col) => {
        //         onSelectionColumn.push(col.field);
        //     });
        // } else {
        //     DEBTOR_GRID_HEADERS.filter((col) => {
        //         onSelectionColumn.push(col.field);
        //     });
        // }

        columns.filter((col, index) => {
            if (index <= columns.length - 1) onSelectionColumn.push(col.field);
        });
            

        if (user) {
            // Client Menu Items
            const _debtor_menu_items = DEBTOR_KEBAB_MENU_ITEMS.filter((col) => {
                const isAllowed = validateUserPermissions(col.required_rights, user.rights);
                if (isAllowed) return col;
            });
            setKebabMenuItems(_debtor_menu_items);
        }
    }, [user]);

    

    return (
        <>
            <div className="col-12 md:col-10">
                <h4 className="m-0">
                    <b>Debtors</b>
                </h4>
            </div>
            {/* <div className="col-12 text-right">
               
            </div> */}
            <div className="md:col-6 col-12">
                <div className="flex mt-4">
                    <Checkbox inputId="showall" checked={showAll === 1 ? true : false} onChange={(e) => setShowAll(e.checked ? 1 : 0)} />
                    <label htmlFor="showall" className="mt-1 ml-2 checkbox-label">
                        Include deleted/terminated debtors
                    </label>
                    {showAll === 1  && (
                        <>
                            <div className="mt-2 ml-3">
                                <Legends classes="square-legend-delete" title="Deleted" />
                            </div>
                            <div className="mt-2 ml-2">
                                <Legends classes="square-legend-terminate" title="Terminated" />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="md:col-6 col-12">
                <div className="flex flex-row">
                    <SearchInputComponent
                        type="text"
                        value={keyword}
                        setKeyword={setKeyword}
                        // handleInput={(e) => setKeyword(e.target.value)}
                        placeholder={"Search by debtor name, client name & phone no"}
                    />
                    <div className="ml-2">
                        <ColumnFilterComponent
                            items={columns}
                            handleColumnFilter={(e) => setSelectedColumns(e)}
                            selectedColumn={onSelectionColumn}
                        />
                    </div>
                    <div className={`ml-2 ${kebabMenuItemLoader ? "no-drop" : ""}`}>
                        {kebab_menu_items.length > 0 && (
                            <DebtorKebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} selected={selectedDebtors} isDisabled={kebabMenuItemLoader} />
                        )}
                    </div>
                    <div className="ml-2">
                        <Header selectedUsersLength={10} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterBar;
