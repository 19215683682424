import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//components
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { BsCheckLg, BsX, BsPaperclip, BsFillEyeFill, BsXLg, BsInfoCircle } from "react-icons/bs";
import { ToastContext } from "../../../../../../context/toast";
import { Fieldset } from "primereact/fieldset";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import { deleteAttachments, getInvoiceClientsDropdown, postAttachments } from "../../../../../../services/invoice";
import { getDebtorPoc, getDebtors, getProspectDebtorsContact } from "../../../../../../services/debtor";
import { MultiSelect } from "primereact/multiselect";
import { getCustomDropDown } from "../../../../../../services/dropdown";
import { fetchCreditLimit } from "../../../../../../services/credit-request";
import { checkInvoiceNo, createSchedule, deleteScheduleInvoice, getScheduleNo, getSchedulesDetail } from "../../../../../../services/schedule-assignment";
import CustomLoading from "../../../../../../ui/CustomSpinner/custom_spinner";
import { BreadCrumb } from "primereact/breadcrumb";
import { openAttachment } from "../../../../../../utils/HelperFuctions/openAttachment";
import { Calendar } from "primereact/calendar";
import { AmountCheck, currencyFormat, currencyFormateToValue } from "../../../../../../utils/HelperFuctions/checkAmount";
import CustomConfirmDialog from "../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { previewDocumentHandler } from "../../../../../../utils/HelperFuctions/documnetPreview";
import { fileName } from "../../../../../../utils/HelperFuctions/getFileName";
import { getDateFormat } from "../../../../../../utils/HelperFuctions/dateFormat";
import { validateUserPermissions } from "../../../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../redux/login";
import { TRANSACTION_RIGHTS } from "../../../../../../utils/Constants/rights";
import { FILE_MAX_SIZE, ROLE_NAME } from "../../../../../../utils/Constants/global";
import { InputNumber } from "primereact/inputnumber";

const initialValidationState = {
    debtor_id: false,
    debtor_contact: false,
    invoice_no: false,
    invoice_date: false,
    invoice_amount: false,
    payment_type: false,
    status: false,
};
const initialValidation = {
    prospect_id: false,
    schedule_no: false,
};

const AddNewScheduleOfAssignment = (props) => {
    // use Query
    const location = useLocation();
    let id = null;
    if (location.state !== undefined) id = location.state?.id;

    const calendarRefs = useRef([]);

    // Redux
    const { user } = useSelector(loginState);

    //toast message
    const toast = useContext(ToastContext);

    //use History
    const history = useHistory();

    //States
    const [formState, setFormState] = useState({
        prospect_id: null,
        schedule_no: null,
        validations: {
            ...initialValidation,
        },
    });
    const [dropdownClients, setDropdownClients] = useState([]);
    const [clientLoading, setClientLoading] = useState(false);
    const [debtorLoading, setDebtorLoading] = useState(true);
    const [dropdownDebtors, setDropdownDebtors] = useState([]);
    const [debtorContacts, setDebtorContacts] = useState([]);
    const [debtorContactsLoading, setDebtorContactsLoading] = useState(true);
    const [paymentDropdowns, setPaymentDropdowns] = useState([]);
    const [statusDropdown, setStatusDropdown] = useState([]);
    const [dropdownsLoading, setDropdownsLoading] = useState(false);
    const [showInvoiceAmountToast, setShowInvoiceAmountToast] = useState(true);
    const [scheduleNoLoading, setScheduleNoLoading] = useState(true);
    const [prospectInfoLoader, setProspectInfoLoader] = useState(true);
    const [openAttachmentLoading, setOpenAttachmentLoading] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [addMoreBtnDisable, setAddMoreBtnDisable] = useState(false);
    const [editScheduleStatus, setEditScheduleStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invoiceNoDisbale, setInvoiceNoDisable] = useState(true);
    const [clientSearch, setClientSearch] = useState("");
    const [zeroFundingData, setZeroFundingData] = useState({
        dialog: false,
        message: "",
        id: null,
        apiData: [],
    });
    const [indexInvoiceNoDisable, setIndexInvoiceNoDisable] = useState([]);
    const [isFormDirty, setIsFormDirty] = useState(false);

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [deleteAttachment, setDeleteAttachment] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);
    const [inputFixedAmount, setInputFixedAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleted, setIsDeleted] = useState();

    const [formArray, setFormArray] = useState([
        {
            debtor_id: "",
            debtor_contact: [],
            invoice_no: "",
            invoice_date: "",
            invoice_amount: "",
            payment_type: "",
            net_invoice_term: "",
            invoice_due_date: "",
            advance_percentage: "",
            status: "",
            advance_amount: "",
            Tentative_payment_mode: "",
            attachments: [],
            commitment_date: "",
            prospect_id: "",
            credit_limit: [],
            validations: {
                ...initialValidationState,
            },
        },
    ]);

    // bread Crumbs
    const BreakcrumbItems = [{ label: "Schedule of Assignment", url: "/scheduleofAssignment" }, { label: isDeleted ? "View Schedule" : (id ? "Edit Schedule" : "Add New Schedule") }];
    const homeIcon = { icon: "bi bi-arrow-repeat" };

    // handle Save Method
    const saveHandler = async () => {
        let hasError = false;
        const updatedFormArray = formArray.map((item) => {
            const { validations, ...rest } = item;
            if (
                item.debtor_id === "" ||
                item.debtor_contact.length === 0 ||
                item.invoice_no === "" ||
                item.invoice_date === "" ||
                parseFloat(item.invoice_amount) <= 1 ||
                item.payment_type === "" ||
                item.status === "" ||
                item.invoice_amount === ""
            ) {
                hasError = true;
                return {
                    ...rest,
                    validations: {
                        ...validations,
                        debtor_id: item.debtor_id === "",
                        debtor_contact: item.debtor_contact.length === 0,
                        invoice_no: item.invoice_no === "",
                        invoice_date: item.invoice_date === "",
                        invoice_amount: parseFloat(item.invoice_amount) <= 1 || item.invoice_amount === "",
                        phone: item.phone === "",
                        payment_type: item.payment_type === "",
                        net_invoice_term: item.net_invoice_term === "",
                        status: item.status === "",
                    },
                };
            } else {
                return {
                    ...rest,
                    validations,
                };
            }
        });
        if (hasError) {
            setFormArray(updatedFormArray);
            return;
        } else {
            setSaveBtnDisabled(true);
            setIsLoading(true);

            const data = formArray.map((item) => {
                const { validations, credit_limit, ...rest } = item;
                return {
                    ...rest,
                    invoice_date: getDateFormat(item.invoice_date),
                    invoice_due_date: getDateFormat(item.invoice_due_date),
                    commitment_date: item.commitment_date ? getDateFormat(item.commitment_date) : null,
                };
            });
            let payload = {
                invoices: data,
                prospect_id: formState.prospect_id,
                schedule_no: formState.schedule_no,
            };
            if (id) {
                payload["id"] = parseInt(id);
            }
            try {
                const res = await createSchedule(payload);
                if (res.data.status == true) {
                    if (id) {
                        toast.updateToast("Schedule");
                    } else {
                        toast.createdToast("Schedule");
                    }
                    history.push("/scheduleofAssignment");
                } else {
                    toast.showMessage("Error", res.data.message, "error");
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        }
        setSaveBtnDisabled(false);
        setIsLoading(false);
    };

    // fetch Clients
    const fetchClients = async (keyword = null) => {
        setClientLoading(true);
        let payload = {
            per_page: 50,
            search_text: keyword,
        };
        try {
            getInvoiceClientsDropdown(payload).then((res) => {
                if (res.data.status) {
                    if (res.data && res.data.prospects && res.data.prospects.data.length > 0) {
                        setDropdownClients(res.data.prospects.data);

                        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
                            setFormState({
                                ...formState,
                                prospect_id: res.data.prospects.data[0].id,
                            });
                            fetchScheduleNo(res.data.prospects.data[0].id);
                        }
                        setClientLoading(false);
                    }
                }
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const handleClientFilter = (event) => {
        setClientSearch(event.filter);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchClients(clientSearch.length === 0 ? null : clientSearch);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [clientSearch]);

    const handleAddMore = () => {
        if (formArray.length === 25) {
            toast.showMessage("Limit", "Only 25 invoices are allowed.", "error");
        } else {
            setFormArray([
                ...formArray,
                {
                    debtor_id: "",
                    debtor_contact: [],
                    invoice_no: "",
                    invoice_date: "",
                    invoice_amount: "",
                    payment_type: "",
                    net_invoice_term: "",
                    invoice_due_date: "",
                    advance_percentage: "",
                    status: "",
                    advance_amount: "",
                    Tentative_payment_mode: "",
                    attachments: [],
                    commitment_date: "",
                    prospect_id: formState.prospect_id,
                    credit_limit: [],
                    validations: {
                        ...initialValidationState,
                    },
                },
            ]);
        }
    };

    const handleRemoveForm = async (index) => {
        if (index !== 0) {
            if (id) {
                setLoading(true);
                const invoice = formArray.filter((_, i) => i === index);
                let payload = {
                    schedule_id: [parseInt(id)],
                    id: [invoice[0].id],
                };
                try {
                    let res = await deleteScheduleInvoice(payload);
                    if (res.data.status) {
                        setLoading(false);
                        toast.deleteToast("Invoice");
                        const updatedFormArray = formArray.filter((_, i) => i !== index);
                        setFormArray(updatedFormArray);
                    }
                } catch (e) {
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
                setLoading(false);
            } else {
                indexInvoiceNoDisable.filter((i) => i !== index);
                setIndexInvoiceNoDisable([]);
                const updatedFormArray = formArray.filter((_, i) => i !== index);
                setFormArray(updatedFormArray);
            }
        }
    };

    // Handlers
    const inputHanlder = (e) => {
        const { name, value } = e.target;
        const _validations = formState.validations;
        setFormState({
            ...formState,
            [name]: value,
            validations: {
                ..._validations,
                [name]: false,
            },
        });
        if (name === "prospect_id") {
            let resetForm = [
                {
                    debtor_id: "",
                    debtor_contact: [],
                    invoice_no: "",
                    invoice_date: "",
                    invoice_amount: "",
                    payment_type: "",
                    net_invoice_term: "",
                    invoice_due_date: "",
                    advance_percentage: "",
                    status: "",
                    advance_amount: "",
                    Tentative_payment_mode: "",
                    attachments: [],
                    commitment_date: "",
                    prospect_id: "",
                    credit_limit: [],
                    validations: {
                        ...initialValidationState,
                    },
                },
            ];
            // adding prospect in the first index of invoice payload...

            let data = [...resetForm];
            data[0].prospect_id = value;
            setFormArray(data);

            // fetch schedule and fetch debtors according to selected prospect...
            fetchScheduleNo(value);
            fetchDebtors(value);
        }
    };
    // Fetching Schedule No
    const fetchScheduleNo = async (prospectId) => {
        setScheduleNoLoading(true);
        let payload = {
            prospect_id: prospectId,
        };
        try {
            let res = await getScheduleNo(payload);
            if (res.data.status) {
                let data = formState;
                data = {
                    ...data,
                    prospect_id: prospectId,
                    schedule_no: res.data.schedule_no,
                };
                setFormState(data);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setScheduleNoLoading(false);
    };
    const handleInputChange = (e, index, title = "") => {
        let status = false;
        let name = "";
        let value = "";

        if (title) {
            name = title;
            value = e.value;
        } else {
            // const { name, value } = e.target;
            name = e.target.name;
            value = e.target.value;
        }

        if (name === "invoice_amount") {
            status = handleCheckCreditLimit(value, index);
        }
        if (status && showInvoiceAmountToast) {
            setShowInvoiceAmountToast(false);
            setInputFixedAmount(value);
            toast.showMessage("Warning", `Invoice amount cannot be greater than debtor's available credit limit($${formArray[index]?.credit_limit?.debtor_active_credit_limit}). `, "warn");
            setSaveBtnDisabled(true);
        } else {
            const updatedFormArray = formArray.map((item, i) => {
                const validations = item.validations;
                if (i === index) {
                    if (name === "invoice_date") {
                        if (value) {
                            return {
                                ...item,
                                [name]: value,
                                validations: {
                                    ...validations,
                                    [name]: false,
                                },
                            };
                        } else {
                            return {
                                ...item,
                                [name]: "",
                                validations: {
                                    ...validations,
                                },
                            };
                        }
                    } else if (name === "commitment_date") {
                        if (value) {
                            return {
                                ...item,
                                [name]: new Date(value),
                                validations: {
                                    ...validations,
                                    [name]: false,
                                },
                            };
                        } else {
                            return {
                                ...item,
                                [name]: "",
                                validations: {
                                    ...validations,
                                },
                            };
                        }
                    } else {
                        return {
                            ...item,
                            [name]: value,
                            validations: {
                                ...validations,
                                [name]: false,
                            },
                        };
                    }
                }
                return item;
            });
            setFormArray(updatedFormArray);

            if (name === "debtor_id") {
                updatedFormArray[index].debtor_contact = [];
                updatedFormArray[index].invoice_amount = 0.0;
                if (!updatedFormArray[index].id) {
                    updatedFormArray[index].invoice_no = "";
                }
                fetchDebtorContacts(value, index, updatedFormArray);
                // Displaying prospect information in text field like advance_percentage etc..
                prospectInformation(index, updatedFormArray);
            } else if (name === "invoice_date") {
                handleInvoiceDate(value, index, updatedFormArray);
            } else if (name === "invoice_amount") {
                handleInvoiceAmount(value, index, updatedFormArray);
            }

            if (!status) {
                setSaveBtnDisabled(false);
                setShowInvoiceAmountToast(true);
            }
        }
    };

    // check debtor Credit limit

    const handleCheckCreditLimit = (value, index) => {
        if (value !== null) {
            value = currencyFormateToValue(value);
            let count = 0;
            const credit_limit = formArray.filter(function (data, i) {
                if (parseInt(formArray[index].debtor_id) === data.debtor_id) {
                    count += 1;
                }
                if (index !== i) {
                    return parseInt(formArray[index].debtor_id) === data.debtor_id;
                }
            });
            let sum_invoice_amount = 0;
            if (count > 1) {
                credit_limit.forEach((element) => {
                    if (element.invoice_amount !== "") {
                        sum_invoice_amount += parseFloat(currencyFormateToValue(element.invoice_amount));
                    }
                });
            }
            sum_invoice_amount += parseFloat(value);

            if (sum_invoice_amount > parseFloat(formArray[index].credit_limit.debtor_active_credit_limit)) {
                return true;
            }
            return false;
        }
    };

    //calculate advance amount using invoice amount
    const handleInvoiceAmount = (invoiceAmount, index, data) => {
        if (invoiceAmount !== null) {
            invoiceAmount = currencyFormateToValue(invoiceAmount);
            if (invoiceAmount !== "") {
                data[index].invoice_amount = invoiceAmount;
                let amount = (parseFloat(invoiceAmount) * parseFloat(data[index].advance_percentage)) / 100;
                let amountToBeFixed = parseFloat(amount).toFixed(2);
                data[index].advance_amount = amountToBeFixed;
                setFormArray(data);
            } else {
                data[index].advance_amount = "";
                setFormArray(data);
            }
        }
    };

    // showing invoice due date
    const handleInvoiceDate = (invoiceDate, index, data) => {
        let invoice_date = new Date(invoiceDate);
        data[index].invoice_due_date = new Date(invoice_date.setDate(invoice_date.getDate() + data[index].net_invoice_term - 1));
        data[index].commitment_date = "";
        setFormArray(data);
    };

    // fetch Debtors
    const fetchDebtors = async (prospectId, keyword = null) => {
        setDebtorLoading(true);
        setClientLoading(true);
        setAddMoreBtnDisable(true);
        let payload = {
            prospect_id: prospectId,
            per_page: 100,
            search_text: keyword,
            status: 0,
        };
        try {
            let res = await getDebtors(payload);
            if (res.data.status) {
                setDropdownDebtors(res.data.debtors.data);
                if (res.data.debtors.data.length > 0) {
                    setDebtorLoading(false);
                }
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setClientLoading(false);
        setAddMoreBtnDisable(false);
    };
    const fetchDebtorContacts = async (debtorId, index, data) => {
        setDebtorContactsLoading(true);
        setSaveBtnDisabled(true);
        setAddMoreBtnDisable(true);
        setInvoiceNoDisable(true);
        let payload = {
            prospect_id: formState.prospect_id,
            debtor_id: debtorId,
        };
        let debtorIdPayload = {
            prospect_id: formState.prospect_id,
            debtor_id: debtorId,
        };
        try {
            let res = await getDebtorPoc(payload);
            if (res.data.status) {
                // setDebtorContacts([...debtorContacts, res.data.pocs]);
                let updatedDebtorContacts = [...debtorContacts];
                updatedDebtorContacts[index] = res.data.pocs;
                setDebtorContacts(updatedDebtorContacts);
            }
            let response = await fetchCreditLimit(debtorIdPayload);
            if (response.data.status) {
                data[index].credit_limit = response.data.credit_limit;
                setFormArray(data);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setDebtorContactsLoading(false);
        setAddMoreBtnDisable(false);
        setSaveBtnDisabled(false);
        setInvoiceNoDisable(false);
    };

    const prospectInformation = (index, data) => {
        setProspectInfoLoader(true);
        let prospect = dropdownClients.filter(function (client) {
            return parseInt(formState.prospect_id) === client.id;
        });
        try {
            data[index].advance_percentage = prospect[0].term.advance_percentage;
            data[index].net_invoice_term = prospect[0].term.discount_days;
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setFormArray(data);
        setProspectInfoLoader(false);
    };

    //DropDowns
    const handleDropdowns = async () => {
        setDropdownsLoading(true);
        let payload = {
            select: ["payment_type", "invoice_status"],
        };
        try {
            getCustomDropDown(payload).then((res) => {
                if (res.data.status) {
                    setPaymentDropdowns(JSON.parse(res.data.dropdowns.payment_type));
                    setStatusDropdown(JSON.parse(res.data.dropdowns.invoice_status));
                }
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setDropdownsLoading(false);
    };
    const handleApiAttachment = async (event, files, index) => {
        setSaveBtnDisabled(true);
        try {
            const formData = new FormData();
            files.forEach((file) => {
                formData.append("attachments[]", file);
            });
            let res = await postAttachments(formData);
            if (res.data.status) {
                formArray[index].attachments = [...formArray[index].attachments, ...res.data.urls];
                setFormArray(formArray);
                event.target.value = null;
            } else {
                toast.showMessage("Error", res.data.message, "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            event.target.value = null;
        }
        setSaveBtnDisabled(false);
    };

    // File Selection Handler
    const onFileSelect = (event, index) => {
        if (formArray[index].attachments.length === 4) {
            toast.showMessage("Exceeding Limit", "You are only authorized to upload 4 files.", "error");
            event.target.value = null;
        } else {
            const multipleFiles = event.target.files;
            const files = [];
            if (multipleFiles.length <= 4) {
                Array.from(multipleFiles).forEach((file) => {
                    const fileType = file.name.split(".");
                    const index = fileType.length - 1;
                    const accept = ".pdf,.jpeg,.png,.jpg";
                    const check_file = accept.includes(fileType[index]);
                    if (check_file) {
                        const fileSize = file.size > FILE_MAX_SIZE.FILE_SIZE;
                        if (fileSize) {
                            toast.showMessage("Exceeding Limit", `${file.name} size is greater than allowed size.`, "error");
                            event.target.value = null;
                        } else if (file.name.includes("#") || file.name.includes("&")) {
                            toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                        } else {
                            files.push(file);
                        }
                    } else {
                        toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
                    }
                });
                handleApiAttachment(event, files, index);
            } else {
                toast.showMessage("Exceeding Limit", "You are only authorized to upload 4 files.", "error");
                event.target.value = null;
            }
        }
    };

    const onLandingScreenHandler = () => {
        if (!validateUserPermissions([TRANSACTION_RIGHTS.schedule_of_assignment.schedule_selection], user?.rights)) {
            history.goBack();
        }
    };

    //UseEffect
    useEffect(() => {
        fetchClients();
        handleDropdowns();
        if (id) {
            onLandingScreenHandler();

            setEditScheduleStatus(true);
            fetchSchedules(id);
        }
    }, []);

    //Edit Scenarios
    const fetchSchedules = async (id) => {
        setLoading(true);
        setProspectInfoLoader(false);
        let payload = {
            id: id,
        };
        try {
            // Fetching schedules invoices
            const res = await getSchedulesDetail(payload);
            if (res.data.status) {
                console.log("resp", res)
                setIsDeleted(res.data?.schedule?.deleted_at ? true : false);
                setFormState({ ...formState, schedule_no: res.data.schedule.schedule_no, prospect_id: res.data.schedule.prospect_id });
                const prospectId = res.data.schedule.prospect_id;
                fetchDebtors(prospectId);
                let payload = {
                    prospect_id: prospectId,
                };
                const resp = await getProspectDebtorsContact(payload);
                if (resp.data.status) {
                    let arr = [];
                    // updating the form Array with validations...
                    res.data.schedule.invoices.map((invoice, index) => {
                        invoice.attachments = invoice.attachments.map((attachment) => attachment.path);
                        arr.push({
                            ...invoice,
                            invoice_date: new Date(invoice.invoice_date),
                            invoice_due_date: new Date(invoice.invoice_due_date),
                            commitment_date: invoice.commitment_date ? new Date(invoice.commitment_date) : "",
                            debtor_contact: typeof invoice.debtor_contact === "string" ? JSON.parse(invoice.debtor_contact) : invoice.debtor_contact,
                            validations: {
                                ...initialValidationState,
                            },
                        });

                        // Inserting Debtor poc for every Invoice and credit limit for every invoice...
                        resp.data.response.filter(function (debtor) {
                            if (parseInt(debtor.id) === parseInt(invoice.debtor_id)) {
                                debtorContacts[index] = debtor.pocs;
                                setDebtorContacts(debtorContacts);
                                debtor.prospects.filter(function (prospect, i) {
                                    if (parseInt(prospect.id) === parseInt(invoice.prospect_id)) {
                                        arr[index]["credit_limit"] = {
                                            debtor_active_credit_limit: prospect.pivot.active_credit_limit,
                                        };
                                    }
                                });
                            }
                        });
                    });
                    setFormArray(arr);
                }
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };
    const handleCheckInvoiceNo = async (e, index) => {
        // Checking if the invoice No for Zero Funding invoices number and other invoices number are same or not...
        let invoices = formArray.filter((invoice, i) => {
            if (i !== index) {
                return invoice.invoice_no;
            }
        });
        if (invoices.length > 0) {
            invoices.map((invoice) => {
                let number = invoice.invoice_no.toString();
                if (number.includes("-")) {
                    number = invoice.invoice_no.split("-")[0];
                }
                if (number === formArray[index].invoice_no) {
                    toast.showMessage("Error", "Invoice can be only be part of a single Schedule, invoice should be unique", "error");
                    formArray[index].invoice_no = "";
                    setFormArray(formArray);
                    return;
                }
            });
        }
        // if not same in frontent then do the rest part for getting invoice no from the backend.
        let invoiceNo = formArray[index]?.invoice_no;
        let payload = {
            invoice_no: invoiceNo,
            prospect_id: formArray[index]?.prospect_id,
            debtor_id: formArray[index]?.debtor_id,
        };
        if (invoiceNo) {
            try {
                const res = await checkInvoiceNo(payload);
                if (res.data.status === false) {
                    let apiData = res.data;
                    if (apiData?.newInvoiceNumber) {
                        setZeroFundingData({
                            ...zeroFundingData,
                            dialog: true,
                            message: apiData?.msg,
                            id: index,
                            apiData: apiData,
                        });
                    } else {
                        formArray[index].invoice_no = "";
                        setFormArray(formArray);
                        toast.showMessage("Error", res.data.message, "error");
                    }
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        }
    };

    // Giving invoice the new Invoice Number that fetch from backend...
    const handleZeroFundingAcknowlege = () => {
        if (calendarRefs) {
            calendarRefs.current.hideOverlay();
        }
        const data = zeroFundingData?.apiData;
        if (data) {
            formArray[zeroFundingData?.id].invoice_no = data.newInvoiceNumber;
            formArray[zeroFundingData?.id].invoice_amount = data.invoice?.invoice_amount;
            formArray[zeroFundingData?.id].invoice_date = new Date(data.invoice?.invoice_date);
            formArray[zeroFundingData?.id].invoice_due_date = new Date(data.invoice?.invoice_due_date);
            formArray[zeroFundingData?.id].advance_amount = data.invoice?.advance_amount;
            formArray[zeroFundingData?.id].payment_type = data.invoice?.payment_type;
            setFormArray(formArray);
            indexInvoiceNoDisable.push(zeroFundingData?.id);
            setIndexInvoiceNoDisable(indexInvoiceNoDisable);
            setZeroFundingData({
                ...zeroFundingData,
                dialog: false,
            });
        }
    };
    const handleZeroFundingCancel = () => {
        const data = zeroFundingData;
        if (data) {
            formArray[data?.id].invoice_no = "";
            setFormArray(formArray);
        }
    };
    const handleShowAttachment = async (file) => {
        setOpenAttachmentLoading(true);
        try {
            await openAttachment(file);
        } catch (error) {
            console.error("Error opening attachment:", error);
        } finally {
            setOpenAttachmentLoading(false);
        }

        // previewDocumentHandler(file);
    };

    const handleCancel = () => {
        if (formState.prospect_id !== null) {
            setIsFormDirty(true);
        } else {
            history.push("/scheduleofAssignment");
        }
    };
    const confirmHandler = () => {
        history.push("/scheduleofAssignment");
    };

    const handleDelete = (file, index, attachmentIndex) => {
        setShowConfirmDeleteDialog(true);
        setDeleteAttachment({
            path: file,
            index: index,
            attachmentIndex: attachmentIndex,
        });
    };

    const confirmDeleteHandler = async () => {
        setIsDeleting(true);
        try {
            let resp = await deleteAttachments(deleteAttachment);

            if (resp.data.status) {
                setShowConfirmDeleteDialog(false);
                toast.showMessage("File Deleted", "File has been deleted successfully", "success");
                const newUploadedFile = formArray[deleteAttachment.index].attachments.filter((item) => item !== deleteAttachment.path);
                formArray[deleteAttachment.index].attachments = newUploadedFile;
                setFormArray(formArray);
            } else {
                toast.showMessage("Deletion Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsDeleting(false);
        } catch (error) {
            console.log(error);
            setIsDeleting(false);
            toast.showMessage("Server Error", "Server error.", "error");
        }
    };


    console.log("formstate", isDeleted)

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    {!loading && <BreadCrumb model={BreakcrumbItems} home={homeIcon} />}
                </div>
            </div>
            {loading ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="addnewschedule_scrollbar">
                        <div className="card">
                            <form className="step-form-top-padding form_scroll">
                                <div className="grid">
                                    <div className="col-12 md:col-6">
                                        <label htmlFor="NameTitle">
                                            <b>
                                                Client Name<span className="clr_red">*</span>
                                            </b>
                                        </label>
                                        <div>
                                            <div>
                                                <Dropdown
                                                    id="prospect_id"
                                                    name="prospect_id"
                                                    placeholder="Select"
                                                    value={formState.prospect_id}
                                                    optionValue="id"
                                                    options={dropdownClients}
                                                    onChange={inputHanlder}
                                                    optionLabel="company_business_name"
                                                    className="mt-2"
                                                    filter={true}
                                                    filterBy="company_business_name"
                                                    onFilter={handleClientFilter}
                                                    disabled={clientLoading || editScheduleStatus}
                                                />
                                            </div>
                                            {formState.validations.prospect_id && <small className="p-error">Client Name is required</small>}
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <label htmlFor="scheduleno">
                                            <b>
                                                Schedule No<span className="clr_red">*</span>
                                            </b>
                                        </label>
                                        <div>
                                            <InputText
                                                id="schedule_no"
                                                name="schedule_no"
                                                type="text"
                                                placeholder="Enter Schedule No"
                                                keyfilter="int"
                                                maxLength="4"
                                                value={formState.schedule_no}
                                                onChange={inputHanlder}
                                                className={`cursor_no_drop mt-2 `}
                                                disabled
                                            />
                                            {formState.validations.schedule_no && <small className="p-error">Schedule No is required</small>}
                                        </div>
                                    </div>
                                </div>
                                {formState.prospect_id &&
                                    formArray.map((item, index) => (
                                        <Fieldset className="positon-relative mt-3" key={index}>
                                            <div className="grid">
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="debtorname">
                                                        <b>
                                                            Debtor Name<span className="clr_red">*</span>
                                                        </b>
                                                    </label>
                                                    <div>
                                                        <Dropdown
                                                            id="debtor_id"
                                                            name="debtor_id"
                                                            placeholder="Select"
                                                            value={item.debtor_id}
                                                            optionValue="id"
                                                            options={dropdownDebtors}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            optionLabel="debtor_name"
                                                            className="mt-2"
                                                            disabled={debtorLoading || isDeleted}
                                                        />
                                                    </div>
                                                    {item.validations.debtor_id && <small className="p-error">Debtor Name is required</small>}
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>
                                                            Debtor Contact<span className="clr_red">*</span>
                                                        </b>
                                                    </label>
                                                    <div>
                                                        <MultiSelect
                                                            id="debtor_contact"
                                                            name="debtor_contact"
                                                            placeholder="Select"
                                                            value={item.debtor_contact}
                                                            options={debtorContacts[index]}
                                                            optionValue="email"
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            optionLabel="email"
                                                            className="mt-2"
                                                            disabled={debtorContactsLoading}
                                                        />
                                                    </div>
                                                    {item.validations.debtor_contact && <small className="p-error">Debtor Contact is required</small>}
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>
                                                            Invoice No<span className="clr_red">*</span>
                                                        </b>
                                                    </label>
                                                    <div>
                                                        <InputText
                                                            id="invoice_no"
                                                            name="invoice_no"
                                                            type="text"
                                                            placeholder="Enter Invoice No"
                                                            value={item.invoice_no}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            onBlur={(e) => handleCheckInvoiceNo(e, index)}
                                                            maxLength={6}
                                                            keyfilter="int"
                                                            className="mt-2"
                                                            disabled={item?.id || indexInvoiceNoDisable.includes(index) || invoiceNoDisbale}
                                                        />
                                                        {item.validations.invoice_no && <small className="p-error">Invoice No is required</small>}
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>
                                                            Invoice Date<span className="clr_red">*</span>
                                                        </b>
                                                    </label>
                                                    <div>
                                                        <Calendar
                                                            id="invoice_date"
                                                            name="invoice_date"
                                                            ref={calendarRefs}
                                                            value={new Date(item.invoice_date)}
                                                            placeholder="mm/dd/yyyy"
                                                            mask="99/99/9999"
                                                            showIcon
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className="mt-2 no-drop"
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange="1945:2050"
                                                            disabled={isDeleted}
                                                        />
                                                        {item.validations.invoice_date && <small className="p-error">Invoice Date is required</small>}
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>
                                                            Invoice Amount<span className="clr_red">*</span>
                                                        </b>
                                                    </label>
                                                    <div>
                                                        {/* <InputText
                                                            id="invoice_amount"
                                                            name="invoice_amount"
                                                            placeholder="Enter Invoice Amount"
                                                            value={currencyFormat(item.invoice_amount)}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            className="mt-2"
                                                            keyfilter="int"
                                                            disabled={prospectInfoLoader}
                                                        /> */}
                                                        <InputNumber
                                                            id="invoice_amount"
                                                            name="invoice_amount"
                                                            placeholder="Enter Invoice Amount"
                                                            value={item.invoice_amount}
                                                            onChange={(e) => handleInputChange(e, index, "invoice_amount")}
                                                            className="mt-2"
                                                            keyfilter="int"
                                                            disabled={prospectInfoLoader || isDeleted}
                                                            mode="currency"
                                                            currency="USD"
                                                            locale="en-US"
                                                            maxFractionDigits={2}
                                                            minFractionDigits={2}
                                                            max={99999999.99}
                                                            min={0}
                                                        />

                                                        {item.validations.invoice_amount && <small className="p-error">Invoice Amount is required</small>}
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>
                                                            Payment Type<span className="clr_red">*</span>
                                                        </b>
                                                    </label>
                                                    <div>
                                                        <Dropdown
                                                            id="payment_type"
                                                            name="payment_type"
                                                            placeholder="Select"
                                                            value={item.payment_type}
                                                            options={paymentDropdowns}
                                                            optionValue="value"
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            optionLabel="name"
                                                            className="mt-2"
                                                            disabled={dropdownsLoading || isDeleted}
                                                        />
                                                    </div>
                                                    {item.validations.payment_type && <small className="p-error">Payment Type is required</small>}
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>
                                                            Net Invoice Term<span className="clr_red">*</span>
                                                        </b>
                                                    </label>
                                                    <div>
                                                        <InputText
                                                            id="net_invoice_term"
                                                            name="net_invoice_term"
                                                            type="text"
                                                            value={item.net_invoice_term}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            maxLength={2}
                                                            keyfilter="int"
                                                            className="mt-2 no-drop"
                                                            disabled
                                                        />
                                                        {item.validations.net_invoice_term && <small className="p-error">Net Invoice Term is required</small>}
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>Invoice Due Date</b>
                                                    </label>
                                                    <div>
                                                        <Calendar
                                                            id="invoice_due_date"
                                                            name="invoice_due_date"
                                                            value={new Date(item.invoice_due_date)}
                                                            placeholder="mm/dd/yyyy"
                                                            mask="99/99/9999"
                                                            className="mt-2 no-drop"
                                                            showIcon
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            disabled
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange="1945:2050"
                                                        />
                                                        {item.validations.invoice_due_date && <small className="p-error">Invoice Due Date is required</small>}
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>
                                                            Advance Percentage<span className="clr_red">*</span>
                                                        </b>
                                                    </label>
                                                    <div>
                                                        <InputText
                                                            id="advance_percentage"
                                                            name="advance_percentage"
                                                            type="text"
                                                            value={item.advance_percentage}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            maxLength={2}
                                                            keyfilter="int"
                                                            className="mt-2 no-drop"
                                                            disabled
                                                        />
                                                        {item.validations.advance_percentage && <small className="p-error">Advance Percentage is required</small>}
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>Advance Amount</b>
                                                    </label>
                                                    <div>
                                                        <InputText
                                                            id="advance_amount"
                                                            name="advance_amount"
                                                            type="text"
                                                            value={item.advance_amount}
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            maxLength={2}
                                                            keyfilter="int"
                                                            className="mt-2 no-drop"
                                                            disabled
                                                        />
                                                        {item.validations.advance_amount && <small className="p-error">Advance Amount is required</small>}
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>
                                                            Status<span className="clr_red">*</span>
                                                        </b>
                                                    </label>
                                                    <div>
                                                        <Dropdown
                                                            id="status"
                                                            name="status"
                                                            placeholder="Select"
                                                            value={item.status}
                                                            options={statusDropdown}
                                                            optionValue="value"
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            optionLabel="name"
                                                            className="mt-2"
                                                            disabled={dropdownsLoading || isDeleted}
                                                        />
                                                    </div>
                                                    {item.validations.status && <small className="p-error">Status is required</small>}
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="NameTitle">
                                                        <b>Commitment Date</b>
                                                    </label>
                                                    <div>
                                                        <Calendar
                                                            id="commitment_date"
                                                            name="commitment_date"
                                                            value={new Date(item.commitment_date)}
                                                            placeholder="mm/dd/yyyy"
                                                            mask="99/99/9999"
                                                            minDate={new Date(item.invoice_date)}
                                                            className="mt-2 no-drop"
                                                            showIcon
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange="1945:2050"
                                                            disabled={isDeleted}
                                                        />
                                                        {item.validations.commitment_date && <small className="p-error">Commitment Date is required</small>}
                                                    </div>
                                                </div>
                                                <div className={`col-12 ${item?.attachments.length > 0 ? "md:col-4" : "md:col-6"}`}>
                                                    <label htmlFor="NameTitle">
                                                        <b>Expected Payment Mode</b>
                                                    </label>
                                                    <div>
                                                        <Dropdown
                                                            id="Tentative_payment_mode"
                                                            name="Tentative_payment_mode"
                                                            placeholder="Select"
                                                            value={item.Tentative_payment_mode}
                                                            options={paymentDropdowns}
                                                            optionValue="value"
                                                            onChange={(e) => handleInputChange(e, index)}
                                                            optionLabel="name"
                                                            className="mt-2"
                                                            disabled={dropdownsLoading || isDeleted}
                                                        />
                                                    </div>
                                                    {item.validations.expectedpaymentmode && <small className="p-error">Expected Payment mode is required</small>}
                                                </div>
                                                <div className={`col-12 ${item?.attachments.length > 0 ? "md:col-4" : "md:col-6"}`}>
                                                    <label htmlFor="NameTitle">
                                                        <b>Attachments</b>
                                                        <Button
                                                            type="button"
                                                            tooltip=".pdf, .jpeg, .png, .jpg files only (max size 3MB)"
                                                            tooltipOptions={{ position: "bottom" }}
                                                            icon={<BsInfoCircle />}
                                                            aria-label="Submit"
                                                            className="customTooltipicon"
                                                        />
                                                    </label>
                                                    <div>
                                                        <div className={`file_upload_class ${isDeleted ? "no-drop" : ""}`}>
                                                            <label htmlFor={`file-upload-${index}`} className={`flex flex-row justify-content-between align-items-center ${isDeleted ? "no-drop p-disabled" : ""}`}>
                                                                Choose File <BsPaperclip />
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            id={`file-upload-${index}`}
                                                            name={index}
                                                            multiple
                                                            onChange={(e) => onFileSelect(e, index)}
                                                            accept=".pdf,.jpeg,.png,.jpg"
                                                            style={{ display: "none" }}
                                                            disabled={saveBtnDisabled || isDeleted}
                                                        />
                                                        {item.validations.attachments && <small className="p-error">Attachments is required</small>}
                                                    </div>
                                                </div>

                                                {item.attachments.length > 0 && (
                                                    <div className="md:col-3 col-12 mt-4 text-center">
                                                        <div>
                                                            {item.attachments.map((file, attachmentIndex) => (
                                                                <>
                                                                    {!id || true ? (
                                                                        <>
                                                                            <div key={attachmentIndex} className="image-item cursor-pointer">
                                                                                {typeof file === "string" && (
                                                                                    <>
                                                                                        <div className="flex flex row justify-content-around" key={attachmentIndex}>
                                                                                            <span className="text_break  cursor-pointer" onClick={() => handleShowAttachment(file)}>
                                                                                                {fileName(file)}
                                                                                            </span>
                                                                                            {!isDeleted &&
                                                                                                <span className="text_break  cursor-pointer" onClick={() => handleDelete(file, index, attachmentIndex)}>
                                                                                                    <BsXLg className="bs_Cros_img" />
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div key={attachmentIndex} className="image-item cursor-pointer" onClick={() => handleShowAttachment(file.path)}>
                                                                                {typeof file.path === "string" && (
                                                                                    <>
                                                                                        <div className="flex flex row justify-content-around" key={attachmentIndex}>
                                                                                            <span className="text_break">{fileName(file.path)}</span>
                                                                                            <BsFillEyeFill onClick={() => handleShowAttachment(file.path)} />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}

                                                {index !== 0 && <Button type="button" onClick={() => handleRemoveForm(index)} className={`transaction_cros_btn { isDeleted ? "no-drop" : ""}`} disabled={isDeleted} icon={<BsX />}></Button>}
                                            </div>
                                        </Fieldset>
                                    ))}

                                {formState.prospect_id && !isDeleted && (
                                    <div className="pt-3 pb-3">
                                        <p>{isDeleted}</p>
                                        <CustomAddNewButton title="Add More +" type="button" onClick={handleAddMore} isDisabled={addMoreBtnDisable} />
                                    </div>
                                )}

                                <div className="grid">
                                    <div className="col-12 pt-3 text-right">


                                        {!isDeleted &&
                                            <>
                                                <CustomCancelbtn title="Cancel" type="button" onClick={() => handleCancel()} />
                                                <CustomAddNewButton title="Save" type="button" onClick={() => saveHandler()} isDisabled={saveBtnDisabled || isDeleted} isLoading={isLoading} />
                                            </>
                                        }

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}

            {openAttachmentLoading && <CustomLoading center />}

            {/* Zero Funding Dialog Box */}

            <CustomConfirmDialog
                header="Zero Funding"
                type="error"
                visible={zeroFundingData?.dialog}
                onHide={() => {
                    setZeroFundingData({
                        ...zeroFundingData,
                        dialog: false,
                    });
                    handleZeroFundingCancel();
                }}
                handler={() => handleZeroFundingAcknowlege()}
                width="50vw"
                icon={<BsCheckLg />}
                title="Acknowledge"
                firsttext={zeroFundingData?.message}
            />

            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsFormDirty(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}

            {showConfirmDeleteDialog && (
                <CustomConfirmDialog
                    header="Confirm Delete"
                    type="error"
                    onHide={() => setShowConfirmDeleteDialog(false)}
                    handler={() => confirmDeleteHandler()}
                    icon={<BsCheckLg />}
                    isDisabled={isDeleting}
                    isCancelDisabled={isDeleting}
                    title="Confirm"
                    firsttext="Are you sure, you want to delete?"
                />
            )}
        </>
    );
};

export default AddNewScheduleOfAssignment;
