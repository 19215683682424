import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import moment from "moment/moment";

//styles
import "../../../userManagement.scss";

// prime react
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { BsCheckLg } from "react-icons/bs";

// Context & APIs
import { deleteUserRole, getAssignedUserRoles, getRoleGrid, setRoleStatus } from "../../../../../services/user-management/user";
import { ToastContext } from "../../../../../context/toast";

// UI Components
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import SearchInputComponent from "../../../../../ui/SearchInput/SearchInputComponent";
import KebabMenuComponent from "../../../../../ui/KebabMenu/KebabMenuComponent";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import ColumnFilterComponent from "../../../../../ui/ColumnFilter/ColumnFilterComponent";
import { PaginatorTemplate } from "../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import DeleteDialog from "./DeleteDialog";
import Header from "../Header/Header";
import { USERMANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../redux/login";

// Utils
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { USERMANAGMENT_ROLES_KEBAB_MENU } from "../../../../../utils/Constants/global";

const RolesRights = () => {
    // Context
    const toastContext = useContext(ToastContext);

    // Hooks
    const history = useHistory();

    // Redux
    const { user } = useSelector(loginState);

    const columns = [
        { field: "roleName", header: "Role Title" },
        // { field: "createdBy", header: "Created By" },
        { field: "createdDate", header: "Created At" },
        { field: "isActive", header: "Status" },
    ];
    const dt = useRef(null);
    //states
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [onSelectionColumn] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [roleCheckData, setRoleCheckData] = useState([]);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [dialogVisibleStatus, setDialogVisibleStatus] = useState(false);

    const [kebab_menu_items, setKebabMenuItems] = useState([]);

    /////////////////////////////////////// PAGINATION REQUIRED DATA START ////////////////////////////////////////////////////
    // API response Data
    const [first, setFirst] = useState(0);
    const [responseData, setReponseData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [page, setPage] = useState(currentPage);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    const onCustomPage = (event) => {
        setFirst(event.first);
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    /////////////////////////////////////// PAGINATION REQUIRED DATA END ////////////////////////////////////////////////////

    //kebab Menu Items

    const handleColumn = (data) => {
        setSelectedColumns(data);
    };

    // Data Table Fields Rendering

    const getColumnBody = (colField) => (rowData) => {
        if (colField === "createdDate") {
            return moment(rowData.createdDate).format("MM/DD/YYYY HH:MM");
        }
        if (colField === "isActive") {
            return rowData.isActive ? "Active" : "Inactive";
        }

        return rowData[colField] ? rowData[colField] : "";
    };

    const columnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} />;
    });

    const handleOpenMenuItems = (status) => {
        if (status === 1 && validateUserPermissions([USERMANAGMENT_RIGHTS.role_rights_deleterole], user?.rights)) {
            deleteRole();
        } else if (status === 2 && validateUserPermissions([USERMANAGMENT_RIGHTS.role_rights_statuschange], user?.rights)) {
            setDialogVisibleStatus(true);
        }
    };

    const getRoles = async () => {
        setLoading(true);
        const dto = {
            pageNumber: currentPage,
            size: pageSize,
            search: search,
            status: showAll ? null : true,
            isLogin: true,
        };
        let resp = await getRoleGrid(dto);
        setLoading(false);
        setIsLoaded(true);
        setReponseData(resp.data);
    };

    const handleRowSelect = (event) => {
        if (!validateUserPermissions([USERMANAGMENT_RIGHTS.role_rights_selection], user?.rights)) return;

        if (event.type === "row") {
            history.push({ pathname: "/editrole", state: { id: `${event.data.roleId}` } });
        }
    };

    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedRoles(event.value);
        }
    };

    const closeModal = () => {
        setShowDeleteDialog(false);
    };

    const deleteRole = async () => {
        try {
            let data = selectedRoles[0];
            let dto = {
                roleId: data.roleId,
            };
            setRoleCheckData([]);
            let resp = await getAssignedUserRoles(dto);
            if (resp.data.length === 0) {
                setShowConfirmDeleteDialog(true);
            } else {
                setRoleCheckData(resp.data);

                setShowDeleteDialog(true);
            }
        } catch (error) {}
    };

    const confirmDeleteRole = async () => {
        try {
            setShowConfirmDeleteDialog(false);
            let data = selectedRoles[0];
            let delResp = await deleteUserRole(data.roleId);
            if (delResp.status) {
                setSelectedRoles([]);
                toastContext.deleteToast("Role");
                getRoles();
            }
        } catch (error) {}
    };

    const changeStatus = async () => {
        try {
            setDialogVisibleStatus(false);
            let data = selectedRoles[0];
            let dto = {
                role_id: data.roleId,
                status: data.isActive ? 0 : 1,
            };
            let resp = await setRoleStatus(dto);
            if (resp.data.status) {
                setSelectedRoles([]);
                toastContext.updateToast("Role Status");
                getRoles();
            }
        } catch (error) {}
    };

    const getRowClassName = (rowData) => {
        if (!rowData.isActive) {
            return "inactive_row_color"; //  delete_row_color
        }
        return "";
    };

    //useEffect
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000); // simulate data loading for 2 seconds
        columns.filter((col) => {
            onSelectionColumn.push(col.field);
        });

        // Kebab Menu
        const _kebab_items = USERMANAGMENT_ROLES_KEBAB_MENU.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

            if (isAllowed) {
                return col;
            }
        });

        setKebabMenuItems(_kebab_items);
    }, [user]);

    useEffect(() => {
        getRoles();
    }, [currentPage, pageSize, showAll]);

    useEffect(() => {
        if (search != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    getRoles();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [search]);

    return (
        <>
            <Dialog blockScroll={true} header="Delete Role" visible={showDeleteDialog} onHide={closeModal} breakpoints={{ "960px": "60vw", "640px": "100vw" }} style={{ width: "50vw" }}>
                <DeleteDialog onHide={closeModal} data={roleCheckData} />
            </Dialog>

            {showConfirmDeleteDialog && (
                <CustomConfirmDialog
                    isDisabled={isDeleting}
                    header="Delete Role"
                    type="error"
                    onHide={() => setShowConfirmDeleteDialog(false)}
                    handler={confirmDeleteRole}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to delete role?"
                />
            )}

            {dialogVisibleStatus && (
                <CustomConfirmDialog
                    header="Change Status"
                    type="error"
                    onHide={() => setDialogVisibleStatus(false)}
                    handler={changeStatus}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure you want to change status?"
                />
            )}

            <div className="grid">
                <div className="col-12 md:col-10">
                    <h4 className="pt-2">
                        <b>Roles & Rights</b>
                    </h4>
                </div>
            </div>

            <div className="grid">
                <div className="md:col-6 col-12">
                    <div className="flex mt-4">
                        <Checkbox inputId="showall" checked={showAll} onChange={(e) => setShowAll(e.checked)} />
                        <label htmlFor="showall" className="mt-1 ml-2 checkbox-label">
                            Include inactive roles
                        </label>
                    </div>
                </div>
                <div className="md:col-6 col-12 pt-3">
                    <div className="flex flex-row justify-content-end">
                        <SearchInputComponent value={search} setKeyword={setSearch} placeholder="Search by role title" />
                        <div className="ml-2">
                            <ColumnFilterComponent items={columns} handleColumnFilter={handleColumn} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className="ml-2">
                            {kebab_menu_items.length > 0 && <KebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} isDisabled={selectedRoles.length == 1 ? false : true} />}
                        </div>
                        <div className="ml-2">
                            <Header selectedRolesLength={selectedRoles.length} />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    {loading ? (
                        <CustomLoading />
                    ) : (
                        <DataTable
                            onSelectionChange={handleSelectionChange} // for checkbox
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            ref={dt}
                            value={responseData?.records}
                            selection={selectedRoles.length === 0 ? null : selectedRoles}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                responseData.isFirstPage ? 1 : 0,
                                responseData.totalPages,
                                currentPage,
                                responseData.totalPages,
                                pageSize,
                                page,
                                responseData.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            globalFilter={globalFilter}
                            emptyMessage="No record available."
                            rowClassName={getRowClassName}
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                        </DataTable>
                    )}
                </div>
            </div>
        </>
    );
};

export default RolesRights;
