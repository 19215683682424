import React, { useEffect, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useSelector } from "react-redux";
import { mainViewState } from "../../../../../redux/main-view";

// Prime React
import { Checkbox } from "primereact/checkbox";
// UI Component
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";

// Helper Function
import { PercentageCheck } from "../../../../../utils/HelperFuctions/checkPercentage";
import { checkDaysUnder120 } from "../../../../../utils/HelperFuctions/checkDays";
import { AmountCheck, amountCheckUnderOneThousand } from "../../../../../utils/HelperFuctions/checkAmount";

// API'S
import { fetchOwners, getTermsheet } from "../../../../../services/term-sheet";
import { TERM_SHEET_CLEARANCE_DAYS, TERM_SHEET_WEEKS } from "../../../../../utils/Constants/global";
import TermsheetDetail from "./TermsheetDetail";
import { useQuery } from "../../../../../utils/HelperFuctions/UseQuery";
import CustomModalSpinner from "../../../../../ui/CustomModalSpinner/CustomModalSpinner";
import ViewTermSheetModal from "./ViewTermSheetModal";
import { Dialog } from "primereact/dialog";
import { BsPaperclip } from "react-icons/bs";

const TermSheet = (props) => {
    const { clientmodal = false } = props;
    // Redux

    const { screens } = useSelector(mainViewState);

    // Prospect Id
    const prospectId = screens.prospect.data ? screens.prospect.data?.id : screens.client.data ? screens.client.data?.id : null;
    // let prospectId = screens.prospect.data?.id;

    // States
    const [termsheet, setTermsheet] = useState({});
    const [owners, setOwners] = useState([]);
    const [termSheetData, setTermSheetData] = useState(null);
    const [isMutated, setIsMutated] = useState(false);

    // View State
    const [previewTermSheet, setPreveiwTermSheet] = useState(false);
    const [termsheetLoading, setTermsheetLoading] = useState(true);
    const [termsheetExist, setTermsheetExist] = useState(false);
    const [companyName, setCompanyName] = useState(false);

    const [ownerLoading, setOwnerLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const [showTermsheet, setShowTermsheet] = useState(false);
    const [signedOwners, setSignedOwners] = useState([]);

    // Hooks
    const query = useQuery();

    // Formik validation
    const validationSchema = Yup.object().shape({
        owner_ids: Yup.array().min(1, "Business Owners is required"),
        factoring_limit: Yup.string().required("Factoring Limit is required"),
        advance_percentage: Yup.number().required("Advance Percentage is required"),
        escrow_reserve_percentage: Yup.string().required("Escrow Reserve Percentage is required"),

        recourse_days: Yup.string().required("Recourse Days is required"),
        purchase_discount: Yup.string().required("Purchase Discount Percentage is required"),
        discount_days: Yup.string().required("Discount Day(s) is required"),
        incremental_discount_days: Yup.string().required("Additional Disocunt Day(s) is required"),

        incremental_discount_percentage: Yup.string().required("Additional Discount Percentage is required"),
        due_deligence_deposit: Yup.string().required("Due Deligence Deposit is required"),
        time_limit_term: Yup.string().required("Exist Fee Intial Term is required"),
        // time_limit_term: Yup.string().required("Exist Fee Intial Term is required"),
        automatic_months_renewal: Yup.string().required("Auto Term Renewal is required"),

        less_than_days: Yup.string().required("Termination Notice Period Days is required"),
        more_than_days: Yup.string().required("Termination Before Renewal Days is required"),
        business_days_clearance: Yup.string().required("Cash Clearance Business Day(s) is required"),
        // cb_percentage: Yup.number().required("Collection Chargeback Percentage is required"),

        proposal_validity: Yup.string().required("Proposal Validity is required"),
        wire_fee: Yup.string().required("Wire Fee is Required"),

        cb_fee: Yup.string().test("required", "Chargeback Fee per day after 90 Days is required", function (value) {
            if (formik.values.is_incremental_purchase_discount) {
                // When 'value' is true, the field is required
                return !!value;
            } else {
                // When 'value' is false, the field is nullable
                return true;
            }
        }),
        cb_per_day_fee: Yup.string().test("required", "Chargeback Fee after 90 Days is required", function (value) {
            if (formik.values.is_incremental_purchase_discount) {
                // When 'value' is true, the field is required
                return !!value;
            } else {
                // When 'value' is false, the field is nullable
                return true;
            }
        }),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            owner_ids: [],
            factoring_limit: "",
            advance_percentage: "",
            escrow_reserve_percentage: "",
            recourse_days: "",
            purchase_discount: "",
            discount_days: "",
            business_days_clearance: "",
            incremental_discount_percentage: "",
            incremental_discount_days: "",
            due_deligence_deposit: "",
            time_limit_term: "",
            automatic_months_renewal: "",
            less_than_days: "",
            more_than_days: "",
            // cb_percentage: "",
            is_incremental_purchase_discount: false,
            cb_per_day_fee: "",
            cb_fee: "",
            proposal_validity: "",
            wire_fee: "",
        },

        onSubmit: async (data) => {
            data = {
                ...data,
                prospect_id: prospectId,
            };
            setTermSheetData(data);
            setPreveiwTermSheet(true);
        },
    });

    // API'S Handlers
    const getOwners = async () => {
        setOwnerLoading(true);
        let form = {
            prospect_id: prospectId,
        };
        let res = await fetchOwners(form);
        if (res?.data.status) {
            setOwners(res.data.owners);
        }
        setOwnerLoading(false);

        return;
    };

    const getActiveTermsheet = async () => {
        setTermsheetLoading(true);
        let form = {
            prospect_id: prospectId,
        };

        getTermsheet(form).then((res) => {
            if (res.data.status) {
                formik.setFieldValue("factoring_limit", res.data.term.factoring_limit);
                formik.setFieldValue("advance_percentage", res.data.term.advance_percentage);
                formik.setFieldValue("escrow_reserve_percentage", res.data.term.escrow_reserve_percentage);
                formik.setFieldValue("recourse_days", res.data.term.recourse_days);
                formik.setFieldValue("purchase_discount", res.data.term.purchase_discount);
                formik.setFieldValue("discount_days", res.data.term.discount_days);
                formik.setFieldValue("due_deligence_deposit", res.data.term.due_deligence_deposit);

                formik.setFieldValue("less_than_days", res.data.term.less_than_days);
                formik.setFieldValue("more_than_days", res.data.term.more_than_days);
                formik.setFieldValue("time_limit_term", res.data.term.time_limit_term);
                formik.setFieldValue("automatic_months_renewal", res.data.term.automatic_months_renewal);
                formik.setFieldValue("business_days_clearance", res.data.term.business_days_clearance);

                formik.setFieldValue("proposal_validity", res.data.term.proposal_validity);
                formik.setFieldValue("incremental_discount_percentage", res.data.term.incremental_discount_percentage);
                // formik.setFieldValue("cb_percentage", res.data.term.cb_percentage ?? "");
                formik.setFieldValue("incremental_discount_days", res.data.term.incremental_discount_days);
                formik.setFieldValue("wire_fee", res.data.term.wire_fee);
                formik.setFieldValue("is_incremental_purchase_discount", res.data.term.cb_fee ? true : false);
                formik.setFieldValue("cb_fee", res.data.term.cb_fee ?? "");
                formik.setFieldValue("cb_per_day_fee", res.data.term.cb_per_day_fee ?? "");
                const selectedOwners = res.data.term.owners.map((owner) => owner.value);
                formik.setFieldValue("owner_ids", selectedOwners);
                setTermsheetExist(true);
                setTermsheet(res.data.term);
                setCompanyName(res.data.company_name);

                setSignedOwners(res.data.term.owners);
            }
            setTermsheetLoading(false);
        });
        return;
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (name === "factoring_limit") {
            formik.setFieldValue(name, AmountCheck(value));
        } else if (
            name === "advance_percentage" ||
            name === "escrow_reserve_percentage" ||
            // name === "cb_percentage" ||
            name === "incremental_discount_percentage" ||
            name === "purchase_discount" ||
            name === "wire_fee"
        ) {
            formik.setFieldValue(name, PercentageCheck(value));
        } else if (name === "due_deligence_deposit") {
            formik.setFieldValue(name, amountCheckUnderOneThousand(value));
        } else if (name === "recourse_days" || name === "discount_days" || name === "incremental_discount_days" || name === "cb_fee" || name === "cb_per_day_fee") {
            formik.setFieldValue(name, checkDaysUnder120(value));
        } else if (name === "proposal_validity") {
            formik.setFieldValue(name, value);
        } else if (name === "time_limit_term" || name === "automatic_months_renewal") {
            if (value == 0) {
                formik.setFieldValue(name, "");
                return;
            } else {
                formik.setFieldValue(name, value);
            }
        } else {
            formik.handleChange(e);
        }
    };
    const handleCurrencyChange = (event, name) => {
        if (event.value !== null) {
            if (name === "due_deligence_deposit") {
                let amount = amountCheckUnderOneThousand(event.value.toString());
                if (event.value > 1000) {
                    formik.setFieldValue(name, amount);
                    return;
                } else {
                    formik.setFieldValue(name, event.value);
                }
            } else if (name === "factoring_limit") {
                let amount = AmountCheck(event.value.toString());
                if (event.value > 100000000) {
                    formik.setFieldValue(name, amount);
                    return;
                } else {
                    formik.setFieldValue(name, event.value);
                }
            } else {
                let amount = PercentageCheck(event.value.toString());
                if (event.value > 101) {
                    formik.setFieldValue(name, amount);
                    return;
                } else {
                    formik.setFieldValue(name, event.value);
                }
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };

    const handleRevisedTerm = () => {
        setTermsheetExist(false);
    };

    useEffect(() => {
        getOwners();
        getActiveTermsheet();
    }, [prospectId, isMutated]);

    useEffect(() => {
        formik.setFieldValue("incremental_discount_days", 1);
    }, []);
    return (
        <>
            {previewTermSheet ? (
                <TermsheetDetail onClose={() => setPreveiwTermSheet(false)} termSheetData={termSheetData} companyName={companyName} setIsMutated={setIsMutated} isMutated={isMutated} />
            ) : (
                <div className="card term_sheet_view">
                    {termsheetLoading ? (
                        clientmodal ? (
                            <CustomModalSpinner />
                        ) : (
                            <CustomLoading top={"50px"} />
                        )
                    ) : (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid">
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    formik={formik}
                                    iden="owner_ids"
                                    label="Business Owners"
                                    type="multiSelect"
                                    mandatory="*"
                                    optionLabel="name"
                                    optionValue="value"
                                    disabled={termsheetExist}
                                    options={owners}
                                    placeHolder="Select"
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="factoring_limit"
                                    label="Factoring Limit"
                                    mandatory="*"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "factoring_limit")}
                                    min={0}
                                    disabled={termsheetExist}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={99999999.99}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="advance_percentage"
                                    label="Advance Percentage"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    maxLength="5"
                                    keyfilter={/^\d*\.?\d*$/}
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Advance Percentage"
                                    onPaste={(e) => e.preventDefault()}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="escrow_reserve_percentage"
                                    label="Escrow Reserve Percentage"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    maxLength="5"
                                    keyfilter={/^\d*\.?\d*$/}
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Escrow Reserve Percentage"
                                    onPaste={(e) => e.preventDefault()}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="recourse_days"
                                    label="Recourse Days"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    maxLength="2"
                                    keyfilter="int"
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Recourse Days"
                                    onPaste={(e) => e.preventDefault()}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="purchase_discount"
                                    label="Purchase Discount Percentage"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    maxLength="4"
                                    keyfilter={/^\d*\.?\d*$/}
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Purchase Discount Percentage"
                                    onPaste={(e) => e.preventDefault()}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="discount_days"
                                    label="Discount Day(s)"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    maxLength="2"
                                    keyfilter="int"
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Discount Day(s)"
                                    onPaste={(e) => e.preventDefault()}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="incremental_discount_percentage"
                                    label="Additional Discount Percentage"
                                    mandatory="*"
                                    maxLength="5"
                                    disabled={termsheetExist}
                                    keyfilter={/^\d*\.?\d*$/}
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Additional Discount Percentage"
                                    onPaste={(e) => e.preventDefault()}
                                />
                            </div>

                            <div className="grid">
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="incremental_discount_days"
                                    label="Additional Discount Day(s)"
                                    mandatory="*"
                                    maxLength="1"
                                    disabled={true}
                                    // disabled={termsheetExist}
                                    keyfilter="int"
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Additional Discount Day(s)"
                                    onPaste={(e) => e.preventDefault()}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="due_deligence_deposit"
                                    label="Due Deligence Deposit"
                                    mandatory="*"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "due_deligence_deposit")}
                                    min={0}
                                    disabled={termsheetExist}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={999.99}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    type="text"
                                    iden="time_limit_term"
                                    label="Exit Fee Initial Term (Months)"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Exit Fee Initial Term (Months)"
                                    onPaste={(e) => e.preventDefault()}
                                    maxLength="2"
                                    keyfilter="int"
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    type="text"
                                    iden="automatic_months_renewal"
                                    label="Auto Term Renewal (Months)"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Auto Term Renewal (Months)"
                                    onPaste={(e) => e.preventDefault()}
                                    maxLength="2"
                                    keyfilter="int"
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="less_than_days"
                                    label="Termination Notice Period Days"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    maxLength="2"
                                    keyfilter="int"
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Termination Notice Period Days"
                                    onPaste={(e) => e.preventDefault()}
                                />{" "}
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="more_than_days"
                                    label="Termination Before Renewal Days"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    maxLength="2"
                                    keyfilter="int"
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Termination Before Renewal Days"
                                    onPaste={(e) => e.preventDefault()}
                                />{" "}
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    type="dropdown"
                                    iden="business_days_clearance"
                                    label="Cash Clearance Business Day(s)"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    options={TERM_SHEET_CLEARANCE_DAYS}
                                    optionLabel="name"
                                    optionValue="value"
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Select"
                                    onPaste={(e) => e.preventDefault()}
                                />
                                {/* <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="cb_percentage"
                                    label="Collection Chargeback Percentage"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    maxLength="5"
                                    keyfilter={/^\d*\.?\d*$/}
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Enter Collection Chargeback Percentage"
                                    onPaste={(e) => e.preventDefault()}
                                /> */}
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    type="dropdown"
                                    iden="proposal_validity"
                                    label="Proposal Validity (Weeks)"
                                    disabled={termsheetExist}
                                    mandatory="*"
                                    options={TERM_SHEET_WEEKS}
                                    optionLabel="name"
                                    optionValue="value"
                                    onChange={(e) => handleInputChange(e)}
                                    formik={formik}
                                    placeHolder="Select"
                                    onPaste={(e) => e.preventDefault()}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="wire_fee"
                                    label="Wire Fee"
                                    mandatory="*"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "wire_fee")}
                                    min={0}
                                    disabled={termsheetExist}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={100}
                                />
                            </div>
                            <div className="grid pt-3 pb-3">
                                <div className="col-12 flex">
                                    <Checkbox
                                        inputId="iipd"
                                        checked={formik.values.is_incremental_purchase_discount}
                                        // checked={checked}
                                        disabled={termsheetExist}
                                        onChange={(e) => {
                                            setChecked(e.checked);
                                            formik.setFieldValue("is_incremental_purchase_discount", e.checked);
                                        }}
                                        className="mt-2"
                                    />
                                    <label htmlFor="iipd" className="mt-2 ml-2 checkbox-label">Chargeback Purchase Discount</label>
                                </div>
                            </div>
                            <div className="grid">
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="cb_fee"
                                    label="Chargeback Fee after 90 Days"
                                    mandatory="*"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "cb_fee")}
                                    min={0}
                                    disabled={!formik.values.is_incremental_purchase_discount || termsheetExist}
                                    // disabled={!checked || termsheetExist}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={100}
                                />
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="cb_per_day_fee"
                                    label="Chargeback Fee per day after 90 Days"
                                    mandatory="*"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "cb_per_day_fee")}
                                    min={0}
                                    disabled={!formik.values.is_incremental_purchase_discount || termsheetExist}
                                    // disabled={!checked || termsheetExist}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={100}
                                />
                            </div>
                            <div className="grid">
                                <div className="col-12 text-right mt-2">
                                    <span className="terms_condition_btn cursor-pointer" onClick={() => setShowTermsheet(true)}>
                                        <BsPaperclip />
                                    </span>
                                    <CustomAddNewButton title="Revise Term Sheet" isDisabled={!termsheetExist} type="button" onClick={handleRevisedTerm} />
                                    <CustomAddNewButton title="Preview Term Sheet" className="ml-2" isDisabled={termsheetExist} type="submit" />
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            )}

            <Dialog
                blockScroll={true}
                header="Term Sheet"
                visible={showTermsheet}
                onHide={() => setShowTermsheet(false)}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw", height: "50vh" }}
            >
                <ViewTermSheetModal signedOwners={signedOwners} term={termsheet} />
            </Dialog>
        </>
    );
};

export default TermSheet;
