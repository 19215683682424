import React, { useState, useRef, useEffect, useContext } from "react";
// UI Components
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
import SearchInputComponent from "../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../ui/ColumnFilter/ColumnFilterComponent";
import KebabMenuComponent from "../../../../ui/KebabMenu/KebabMenuComponent";
import { PaginatorTemplate } from "../../../../ui/PaginatorTemplate/PaginatorTemplate";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
// Components
// Prime react
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BsCheckLg } from "react-icons/bs";
import { Tooltip } from "primereact/tooltip";
// Context & APIs
import { ToastContext } from "../../../../context/toast";
import HeaderPolicies from "../Header/header";
import { Checkbox } from "primereact/checkbox";
import { deletePolicy, getPolicy } from "../../../../services/policy";
import { trimTextWithDots } from "../../../../utils/HelperFuctions/trimText";
import Document from "../../../../svgs/Document/Document";

import { dateTimeFormatForInputFields } from "../../../../utils/HelperFuctions/dateFormat";
import { openAttachment } from "../../../../utils/HelperFuctions/openAttachment";
import { Dialog } from "primereact/dialog";
import AddNewPolicy from "../Components/addnewpolicy";
import { SETTINGS_RIGHTS } from "../../../../utils/Constants/rights";
import { loginState } from "../../../../redux/login";
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { BreadCrumb } from "primereact/breadcrumb";
import appUrl from "../../../../utils/Constants/appUrl";

const PoliciesIndex = () => {
    const toastContext = useContext(ToastContext);
    const [showTooltip, setShowTooltip] = useState(false);

    // bread Crumbs
    const BreakcrumbItems = [{ label: "Settings", url: "/settings" }, { label: "Policies" }];
    const homeIcon = { icon: "bi bi-toggle-off" };

    // Redux
    const { user } = useSelector(loginState);

    const columns = [
        { field: "name", header: "Policy Title" },
        { field: "effective_from", header: "Effective From" },
        { field: "description", header: "Description" },
        { field: "attachment_path", header: "Attachments" },
        { field: "first_name", header: "Created By" },
        { field: "created_at", header: "Created At" },
    ];
    //kebab Menu Items
    const kebabMenuItems = [{ title: "Delete", status: 1, required_rights: [SETTINGS_RIGHTS.policies.delete_policies] }];

    const dt = useRef(null);
    //states
    const [selectedPolicies, setSelectedPolicies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [onSelectionColumn] = useState([]);

    const [editPolicyDialog, setEditPolicyDialog] = useState(false);
    const [editPolicyData, setEditPolicyData] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [openAttachmentLoading, setOpenAttachmentLoading] = useState(false);
    const [formDirty, setFormDirty] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    /////////////////////////////////////// PAGINATION REQUIRED DATA START ////////////////////////////////////////////////////
    const [responseData, setReponseData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [page, setPage] = useState(currentPage);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };
    /////////////////////////////////////// PAGINATION REQUIRED DATA END ////////////////////////////////////////////////////
    const handleColumn = (data) => {
        setSelectedColumns(data);
    };

    const openDocument = async (event, attachment) => {
        if (attachment) {
            try {
                setOpenAttachmentLoading(true);
                event.stopPropagation();
                await openAttachment(attachment);
            } catch (error) {
                console.error("Error opening attachment:", error);
            } finally {
                setOpenAttachmentLoading(false);
            }
            // window.open(appUrl.imageBaseUrl + attachment, "_blank");
        }
    };
    const getColumnBody = (colField) => (rowData) => {
        const handleMouseEnter = () => {
            setShowTooltip(true);
        };

        const handleMouseLeave = () => {
            setShowTooltip(false);
        };
        if (colField === "description") {
            return (
                <>
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <span id={`name-${rowData.id}`}>{trimTextWithDots(rowData.description, 25)}</span>
                        {showTooltip && (
                            <Tooltip target={`#name-${rowData.id}`} position="left" onHide={() => setShowTooltip(false)}>
                                {rowData.description}
                            </Tooltip>
                        )}
                    </div>
                </>
            );
        } else if (colField === "attachment_path") {
            return (
                <>
                    <div className={` mr-2 mt-1 ${rowData.attachment_path ? "document_icon_background" : "no_doc"}`} onClick={(event) => openDocument(event, rowData.attachment_path)}>
                        {rowData.attachment_path ? <Document /> : "No Attachment"}
                    </div>
                </>
            );
        } else if (colField === "created_at") {
            return dateTimeFormatForInputFields(rowData.created_at);
        } else if (colField === "first_name") {
            return (rowData.last_name ? rowData.last_name : "") + " " + (rowData.first_name ? rowData.first_name : "");
        }
        return rowData[colField] ? rowData[colField] : "";
    };

    const columnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} sortable={col.sortable} />;
    });

    const handleOpenMenuItems = (status) => {
        if (status === 1) {
            setShowDeleteDialog(true);
        }
    };

    const deletePoliciesHandler = async () => {
        try {
            setIsDeleting(true);
            const ids = selectedPolicies.map((policy) => policy.id);
            let dto = {
                ids: ids,
            };
            let resp = await deletePolicy(dto);
            if (resp.data.status) {
                setIsDeleting(false);
                setSelectedPolicies([]);
                setShowDeleteDialog(false);
                toastContext.deleteToast(selectedPolicies.length > 1 ? "Policies" : "Policy");
                fetchPolicies();
            }
        } catch (error) {
            setIsDeleting(false);
        }
    };

    const handleRowSelect = (event) => {
        if (!validateUserPermissions([SETTINGS_RIGHTS.policies.policies_selection], user?.rights)) return;
        if (event.type === "row") {
            setEditPolicyData(event.data);
            setEditPolicyDialog(true);
        }
    };

    const handleSelectionChange = (event) => {
        if (!validateUserPermissions([SETTINGS_RIGHTS.policies.policies_selection], user?.rights)) return;
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedPolicies(event.value);
        }
    };

    const fetchPolicies = async () => {
        setLoading(true);
        const dto = {
            page: currentPage,
            per_page: pageSize,
            search_text: search,
            show_all: showAll,
        };

        try {
            const res = await getPolicy(dto);
            if (res.data.status) setReponseData(res.data.policies);
            setPagination({
                ...pagination,
                totalRecords: res.data.policies.total,
                totalPages: res.data.policies.last_page,
                isLastPage: res.data.policies.last_page,
            });
        } catch (e) {
            toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    //useEffect
    useEffect(() => {
        columns.filter((col) => {
            onSelectionColumn.push(col.field);
        });
    }, []);

    useEffect(() => {
        fetchPolicies();
    }, [currentPage, pageSize, showAll]);

    useEffect(() => {
        if (search != null) {
            const delayDebounceFn = setTimeout(() => {
                fetchPolicies();
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [search]);

    const getRowClassName = (rowData) => {
        if (rowData.deleted) {
            return "delete_row_color";
        }
        return "";
    };

    const onCancel = (refresh) => {
        if (refresh) {
            fetchPolicies();
        }
        setEditPolicyDialog(false);
    };

    return (
        <>
            <div className="grid">
                <div className="col-12 pt-3 pb-3">
                    <BreadCrumb model={BreakcrumbItems} home={homeIcon} />
                </div>
            </div>
            <div className="grid">
                <div className="md:col-6 col-12">
                    <div className="flex mt-4">
                        <Checkbox inputId="showall" checked={showAll} onChange={(e) => setShowAll(e.checked)} />
                        <label htmlFor="showall" className="mt-1 ml-2 checkbox-label">
                            Include deleted policies
                        </label>
                    </div>
                </div>
                <div className="md:col-6 col-12 pt-3">
                    <div className="flex flex-row">
                        <SearchInputComponent value={search} setKeyword={setSearch} placeholder="Search by policy title" />
                        <div className="ml-2">
                            <ColumnFilterComponent items={columns} handleColumnFilter={handleColumn} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className="ml-2">
                            <KebabMenuComponent items={kebabMenuItems} handleMenuOpen={handleOpenMenuItems} isDisabled={selectedPolicies.length > 0 ? false : true} />
                        </div>
                        <div className="ml-2">
                            <HeaderPolicies fetchPolicies={fetchPolicies} selectedPolicies={selectedPolicies.length} />
                        </div>
                    </div>
                </div>
                <div className="col-12 pt-3">
                    {loading ? (
                        <CustomLoading />
                    ) : (
                        <DataTable
                            ref={dt}
                            value={responseData?.data}
                            selection={selectedPolicies.length === 0 ? null : selectedPolicies}
                            onSelectionChange={handleSelectionChange}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            rowClassName={getRowClassName}
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                        </DataTable>
                    )}
                </div>
            </div>

            {showDeleteDialog && (
                <CustomConfirmDialog
                    isDisabled={isDeleting}
                    header={selectedPolicies.length > 1 ? "Delete Policies" : "Delete Policy"}
                    type="error"
                    onHide={() => setShowDeleteDialog(false)}
                    handler={deletePoliciesHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext={`Are you sure, you want to delete ${selectedPolicies.length > 1 ? "policies" : "policy"}?`}
                />
            )}

            {openAttachmentLoading && <CustomLoading center />}

            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={() => {
                        setEditPolicyDialog(false);
                        setIsVisible(false);
                    }}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}

            <Dialog
                header="Edit New Policy"
                visible={editPolicyDialog}
                onHide={() => {
                    formDirty ? setIsVisible(true) : setEditPolicyDialog(false);
                }}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw" }}
            >
                <AddNewPolicy onHide={(refresh) => onCancel(refresh)} setFormDirty={setFormDirty} editData={editPolicyData} />
            </Dialog>
        </>
    );
};

export default PoliciesIndex;
